import validator from "validator";
import moment from "moment";
import IntlMessages from '../helpers/IntlMessages';

import {
    SELECT_DAY,
    GET_ROOMS,
    SELECT_ROOM,
    CLEAR_ROOM,
    GET_TIMESLOTS,
    SELECT_TIME,
    CLEAR_TIMESLOT,
    SELECT_ADULTS,
    SELECT_CHILDREN,
    CHECK_FIDELITY,
    CHANGE_FIRSTNAME,
    ACCEPT_TERMINI,
    CHANGE_EMAIL,
    CHANGE_PHONE,
    CHANGE_LASTNAME,
    CHANGE_CARDNUMBER,
    CHANGE_EXPIRATIONDATE,
    CHANGE_CARDHOLDERNAME,
    CHANGE_IDCREDITCARD,
    SECURITY_CODE,
    BACK_STEP,
    SELECT_SEGGIOLONE
} from "../const";
import { errorAction, handleDisableButton, validateAction, selectTimeSlot } from "../actions";
//import { sendConfirmationEmailAction } from '../actions/sendConfirmationEmailAction'

const errorValidation = ({ dispatch, getState, intl }) => next => action => {

    let errors = {};
    let step = getState().step;
    let roomSelected = getState().reservation.room;
    let postiDisponibili = Number(getState().totalSeats);
    let peopleSeats = Number(getState().peopleSeats);
    let hasChildrenArea = Number(getState().childrenSeats);
    let seggioloni = Number(getState().seggioloni);


    let children = Number(getState().reservation.person.children);
    let adults = Number(getState().reservation.person.adults);
    let maxSeatsAvailable, maxPeopleSeats, isSecretRoom, isNHRoom, maxSeatsForBook, maxChildrendSeats, isChildrenArea;

    switch (action.type) {
        case BACK_STEP:
            if(step === "2") {
                dispatch(selectTimeSlot(""));
            }
            next(action);
            break;
        case SELECT_DAY:
            const availableDays = getState().availableDays;
            let arrayDays = [];
            for (let i = 0; i < availableDays.length; i++) {
                arrayDays.push(availableDays[i][0]);
            }
            errors = true ? null : "Data non disponibile";
            dispatch(errorAction(errors, "date"));
            if (errors) {
                dispatch(handleDisableButton(true));
            } else {
                dispatch(handleDisableButton(false));
            }
            next(action);
            break;
        case GET_ROOMS:
            const rooms = action.payload;
            errors = rooms === "" || rooms === undefined ? "Nessuna sala disponibile" : null;
            dispatch(errorAction(errors, "rooms"));
            dispatch(handleDisableButton(true));
            next(action);
            break;
        case SELECT_ROOM:
            const room = action.payload;
            errors = room === "" ? "Seleziona una sala" : null;
            dispatch(errorAction(errors, "room"));
            if (errors) {
                dispatch(handleDisableButton(true));
            } else {
                dispatch(handleDisableButton(false));
            }
            next(action);
            break;
        case CLEAR_ROOM:
            errors = "Seleziona una sala";
            dispatch(errorAction(errors, "room"));
            if (errors) {
                dispatch(handleDisableButton(true));
            } else {
                dispatch(handleDisableButton(false));
            }
            next(action);
            break;
        case GET_TIMESLOTS:
            const timeslots = action.payload;
            errors = timeslots === "" || timeslots === undefined || timeslots === null || timeslots.length <= 0 ? "Non ci sono posti disponibili, la invitiamo a chiamare il numero 06 333 2961 per verificare con il nostro staff." : null;
            dispatch(errorAction(errors, "timeslots"));
            if (errors) {
                dispatch(handleDisableButton(true));
            }
            next(action);
            break;
        case SELECT_TIME:
            const time = action.payload;
            errors = time === "" ? "Seleziona un orario disponibile" : null;
            dispatch(errorAction(errors, "time"));
            if (errors) {
                dispatch(handleDisableButton(true));
            } else {
                dispatch(handleDisableButton(false));
            }
            next(action);
            break;
        case CLEAR_TIMESLOT:
            errors = "Seleziona un orario disponibile";
            dispatch(errorAction(errors, "time"));
            if (errors) {
                dispatch(handleDisableButton(true));
            } else {
                dispatch(handleDisableButton(false));
            }
            next(action);
            break;
        case SELECT_ADULTS:
            var minSeatsAvailable;
            adults = Number(action.payload);
            //verifiche sui pax
            maxPeopleSeats = adults > peopleSeats ? true : false;
            maxSeatsAvailable = adults + children > postiDisponibili ? true : false;
            isSecretRoom = roomSelected === "7" && adults !== 2 ? true : false;

            if (roomSelected === "12"){
                minSeatsAvailable = adults < 4 ? true : false;
                maxSeatsForBook = adults + children > 4 ? true : false;
            } else if(roomSelected === "15") {
                minSeatsAvailable = adults < 2 ? true : false;
                maxSeatsForBook = adults + children > 4 ? true : false;
                isNHRoom = adults === 2 || adults === 4 ? false : true;
            } else {
                minSeatsAvailable = adults < 1 ? true : false;
                maxSeatsForBook = adults + children > 4 ? true : false;
            }
            maxChildrendSeats = children > hasChildrenArea ? true : false;
            isChildrenArea = hasChildrenArea === 0 && children > 0 ? true : false; 

            errors =
                maxPeopleSeats ||
                isSecretRoom || 
                isNHRoom ||
                minSeatsAvailable ||
                maxSeatsAvailable ||
                maxSeatsForBook ||
                maxChildrendSeats ||
                isChildrenArea ?
                "Coperti non disponibili" :
                adults === 0 || adults === "" ?
                "Inserire almeno un coperto" :
                null;


            dispatch(errorAction(errors, "person"));
            if (errors) {
                dispatch(handleDisableButton(true));
            } else {
                dispatch(handleDisableButton(false));
            }
            next(action);
            break;
        case SELECT_CHILDREN:
            children = Number(action.payload);
            //verifiche sui pax
            maxSeatsAvailable = adults + children > postiDisponibili ? true : false;
            isSecretRoom = roomSelected === "7" && adults !== 2 ? true : false;
            maxSeatsForBook = adults + children > 4 ? true : false;
            maxChildrendSeats = children > hasChildrenArea ? true : false;
            isChildrenArea = hasChildrenArea === 0 && children > 0 ? true : false; 
            
            
            errors =
                children !== '' ||
                isSecretRoom ||
                maxSeatsAvailable ||
                maxSeatsForBook ||
                maxChildrendSeats ||
                isChildrenArea ?
                "Coperti non disponibili" :
                null;

            dispatch(errorAction(errors, "person"));
            if (errors) {
                dispatch(handleDisableButton(true));
            } else {
                dispatch(handleDisableButton(false));
            }

            next(action);
            break;
        case SELECT_SEGGIOLONE:
            let seggioloniBook = Number(action.payload);
            errors = seggioloniBook > seggioloni ? "Seggioloni non disponibili" : null;

            dispatch(errorAction(errors, "seggioloni"));
            if (errors) {
                dispatch(handleDisableButton(true));
            } else {
                dispatch(handleDisableButton(false));
            }
            next(action);
            break;
        case CHECK_FIDELITY:
            const fidelity = action.payload;

            //errors = fidelity[0] === 0 ? "Tessera non trovata!" : fidelity[1];

            //if (fidelity[0] === 0) {
            if (fidelity.length > 3) {
                dispatch(handleDisableButton(true));
                //dispatch(errorAction(errors, "fidelity"));
                //dispatch(validateAction(false, "fidelity"));
            } else {
                dispatch(handleDisableButton(false));
                //dispatch(validateAction(true, "fidelity"));
                //dispatch(errorAction(errors, "fidelity"));
            }

            next(action);
            break;
            //9) inserire solo stringa senza caratteri speciali (nome, cognome)
        case CHANGE_FIRSTNAME:
            const firstName = action.payload;

            errors = firstName.length > 3 ?
                null :
                "Nome non valido";

            //if errore => dispatch errors + dispatch validate false else dispatch
            if (firstName.length > 3) {
                dispatch(validateAction(true, "firstName"));
                dispatch(errorAction(errors, "firstName"));
            } else {
                dispatch(validateAction(false, "firstName"));
                dispatch(errorAction(errors, "firstName"));
            }

            next(action);
            break;
        case CHANGE_LASTNAME:
            const lastName = action.payload;

            errors = lastName.length > 3 ? null : "Cognome non valido";

            if (lastName.length > 3) {
                dispatch(validateAction(true, "lastName"));
                dispatch(errorAction(errors, "lastName"));
            } else {
                dispatch(validateAction(false, "lastName"));
                dispatch(errorAction(errors, "lastName"));
            }

            next(action);
            break;
            //10) inserisci una email valida
        case CHANGE_EMAIL:
            const email = action.payload;

            errors = validator.isEmail(email) ? null : "Email non valida";

            if (validator.isEmail(email)) {
                dispatch(validateAction(true, "email"));
                dispatch(errorAction(errors, "email"));
            } else {
                dispatch(validateAction(false, "email"));
                dispatch(errorAction(errors, "email"));
            }

            next(action);
            break;
            //11) inserisci un numero di telefono valido
        case CHANGE_PHONE:
            const phone = action.payload;

            errors = validator.isMobilePhone(phone) ?
                null :
                "Telefono non valido";

            if (validator.isMobilePhone(phone)) {
                dispatch(validateAction(true, "phone"));
                dispatch(errorAction(errors, "phone"));
            } else {
                dispatch(validateAction(false, "phone"));
                dispatch(errorAction(errors, "phone"));
            }

            next(action);
            break;
        case ACCEPT_TERMINI:
            if (action.payload){
                    dispatch(handleDisableButton(false));
            } else {
                errors = "Accetta i termini per proseguire."
                dispatch(errorAction(errors, "TermsCoditions"));
                dispatch(handleDisableButton(true));
            }
            next(action);
            break;

        case CHANGE_IDCREDITCARD:
            const idCreditCard = action.payload;
            errors = idCreditCard === "" ? "Seleziona un tipo di carta" : null;
            if (errors) {
                dispatch(validateAction(false, "IdCreditCardType"));
                dispatch(errorAction(errors, "IdCreditCardType"));
            } else {
                dispatch(validateAction(true, "IdCreditCardType"));
                dispatch(errorAction(errors, "IdCreditCardType"));
            }
            next(action);
            break;
        case CHANGE_CARDNUMBER:
            const CardNumber = action.payload;
            if (CardNumber === ""){
                errors = "Inserisci il numero identificativo della carta";
            } else if (CardNumber.length < 13) {
                errors = "Numero della carta non valido";
            } else {
                errors = null;
            }
            
            if (errors) {
                dispatch(validateAction(false, "CardNumber"));
                dispatch(errorAction(errors, "CardNumber"));
            } else {
                dispatch(validateAction(true, "CardNumber"));
                dispatch(errorAction(errors, "CardNumber"));
            }
            next(action);
            break;
        case CHANGE_EXPIRATIONDATE:
            const ExpirationDate = action.payload;

            let mm = ExpirationDate.slice(0, 2);
            let yy = ExpirationDate.slice(3, 5);

            let ExpirationDateFormat = moment("20"+yy+"-"+mm+"-01", "YYYY-MM-DD");

            if (ExpirationDate === ""){
                errors = "Inserisci la scadenza";
            } else if (!moment(ExpirationDateFormat, "YYYY-MM-DD").isValid()){
                errors = "Scadenza non valida";
            } else if (ExpirationDateFormat > moment().add(10, 'years')) {
                errors = "Scadenza non valida";
            } else {
                errors = null;
            }

            if (errors) {
                dispatch(validateAction(false, "ExpirationDate"));
                dispatch(errorAction(errors, "ExpirationDate"));
            } else {
                dispatch(validateAction(true, "ExpirationDate"));
                dispatch(errorAction(errors, "ExpirationDate"));
            }
            next(action);
            break;
        case CHANGE_CARDHOLDERNAME:
            const CardholderName = action.payload;
            errors = CardholderName === "" ? "Inserisci nome e cognome del titolare riportati sul fronte della carta" : null;
            if (errors) {
                dispatch(validateAction(false, "CardholderName"));
                dispatch(errorAction(errors, "CardholderName"));
            } else {
                dispatch(validateAction(true, "CardholderName"));
                dispatch(errorAction(errors, "CardholderName"));
            }
            next(action);
            break;
        case SECURITY_CODE:
            const SecurityCode = action.payload;
            if (SecurityCode === "" || SecurityCode.length < 3){
                errors = "Inserisci il codice di sicurezza presente sul retro della carta";
            } else {
                errors = null;
            }
            if (errors) {
                dispatch(validateAction(false, "SecurityCode"));
                dispatch(errorAction(errors, "SecurityCode"));
            } else {
                dispatch(validateAction(true, "SecurityCode"));
                dispatch(errorAction(errors, "SecurityCode"));
            }
            next(action);
            break;
            // case SAVE_BOOKING:
            //   const data = action.payload;
            //   console.log(data);
            //   errors = data["Confirmed"][0] ? null : data["InformationMessage"][0];
            //   console.log(data["Confirmed"][0]);

            //   if (data["Confirmed"][0] == true) {
            //     //dispatch(sendConfirmationEmailAction(reservation, user));
            //     dispatch(errorAction(errors, "saveBookings"));
            //   } else {
            //     dispatch(errorAction(errors, "saveBookings"));
            //   }
            //   break;
        default:
            next(action);
            break;
    }


}

export default errorValidation;