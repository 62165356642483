// import {GET_ROOMS} from "../const";
// import RandomFidelityPublic from '../apis/RandomFidelityPublic';

// export const GetRestaurantRooms = (Token, IdLocation, ReservationDate) => async dispatch => {
//     const promise = await RandomFidelityPublic.post('/GetRestaurantRooms.php', {
//         'Token': Token,
//         'IdLocation': IdLocation,
//         'ReservationDate': ReservationDate
//     },
//     {
//         // params: {
//         //     op: "GetRestaurantRooms"
//         // }
//     });
//     dispatch({
//         type: GET_ROOMS,
//         payload: promise.data
//     });
// }


import Octopus from "../apis/Octopus";

import {
    GET_ROOMS,
    ERROR,
    TOKEN,
} from "../const";
import xml2js from "xml2js";


export const GetRestaurantRooms = (date) => async dispatch => {

    let body = `<?xml version="1.0" encoding="utf-8" ?>
            <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
            <soap:Body>
                <GetRestaurantRooms xmlns="http://tempuri.org/">
                <p>
                    <Token>` + TOKEN + `</Token>
                    <IdLocation>1</IdLocation>
                    <ReservationDate>` + date + `</ReservationDate>
                </p>
                </GetRestaurantRooms>
            </soap:Body>
            </soap:Envelope>`;

    const promise = await Octopus.post("", body, {
        params: {
            op: "GetRestaurantRooms"
        }
    });

    var parser = new xml2js.Parser();

    parser.parseStringPromise(promise.data)
        .then(function (result) {

            const data =
                result["soap:Envelope"]["soap:Body"][0]
                    .GetRestaurantRoomsResponse[0][
                "GetRestaurantRoomsResult"
                ][0]["Rooms"][0]['Room'];

            dispatch({
                type: GET_ROOMS,
                payload: data
            })
        })
        .catch(function (err) {
            let input = "room";
            dispatch({
                type: ERROR,
                payload: { err, input }
            })
        });
};   