import {
    NEXT_STEP,
    BACK_STEP,
    SELECT_ROOM,
    CLEAR_ROOM,
    ROOM_NAME,
    SELECT_DAY,
    SELECT_TIME,
    CLEAR_TIMESLOT,
    SELECT_TIME_ID,
    SELECT_SERVIZIO,
    SELECT_ADULTS,
    SELECT_CHILDREN,
    SELECT_SEGGIOLONE,
    CHANGE_FIRSTNAME,
    CHANGE_LASTNAME,
    CHANGE_EMAIL,
    CHANGE_PHONE,
    CHANGE_NOTE,
    CHANGE_MARKETING,
    HAS_FIDELITY,
    CHANGE_FIDELITY,
    CHECK_FIDELITY,
    DISABLE_BUTTON,
    ACCEPT_TERMINI,
    OPEN_DIALOG,
    ERROR,
    VALIDATE,
    CREDIT_CARD_ROOM,
    CHANGE_CARDNUMBER,
    CHANGE_EXPIRATIONDATE,
    CHANGE_CARDHOLDERNAME,
    CHANGE_IDCREDITCARD,
    SECURITY_CODE,
    DISTANZIAMENTO,
    TOTAL_SEATS,
    PEOPLE_SEATS,
    CHILD_SEATS,
    SEGGIOLONI,
    CHANGE_LOCALE,
} from "../const";

import { setCurrentLanguage } from '../helpers/Utils';

export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};


export const nextStep = () => async dispatch => {
    dispatch({
        type: NEXT_STEP
    });
};

export const backStep = () => async dispatch => {
    dispatch({
        type: BACK_STEP
    });
};

export const selectRoomAction = (number, name) => dispatch => {
    dispatch({
        type: SELECT_ROOM,
        payload: number
    });
    dispatch({
        type: ROOM_NAME,
        payload: name
    });
};

export const clearRoomAction = () => dispatch => {
    dispatch({
        type: CLEAR_ROOM
    });
};

export const selectDataAction = e => dispatch => {
    dispatch({
        type: SELECT_DAY,
        payload: e
    });
};

export const selectTimeSlot = (id, time, servizio, hasCreditCard, totalSeats, peopleSeats, childrenSeats, seggioloni) => dispatch => {
    dispatch({
        type: SELECT_TIME,
        payload: time
    });
    dispatch({
        type: SELECT_TIME_ID,
        payload: id
    });
    dispatch({
        type: SELECT_SERVIZIO,
        payload: servizio
    });
    dispatch({
        type: CREDIT_CARD_ROOM,
        payload: hasCreditCard
    });
    dispatch({
        type: TOTAL_SEATS,
        payload: totalSeats
    });
    dispatch({
        type: PEOPLE_SEATS,
        payload: peopleSeats
    });
    dispatch({
        type: CHILD_SEATS,
        payload: childrenSeats
    });
    dispatch({
        type: SEGGIOLONI,
        payload: seggioloni
    });
};

export const clearTimeSlotAction = () => dispatch => {
    dispatch({
        type: CLEAR_TIMESLOT
    });
};

export const selectAdults = e => dispatch => {
    dispatch({
        type: SELECT_ADULTS,
        payload: e
    });
};

export const selectChildren = e => dispatch => {
    dispatch({
        type: SELECT_CHILDREN,
        payload: e
    });
};
export const selectSeggiolone = e => dispatch => {
    dispatch({
        type: SELECT_SEGGIOLONE,
        payload: e
    });
};
export const changeFirstName = e => dispatch => {
    dispatch({
        type: CHANGE_FIRSTNAME,
        payload: e
    });
};

export const changeLastName = e => dispatch => {
    dispatch({
        type: CHANGE_LASTNAME,
        payload: e
    });
};

export const changeEmail = e => dispatch => {
    dispatch({
        type: CHANGE_EMAIL,
        payload: e
    });
};

export const changePhone = e => dispatch => {
    dispatch({
        type: CHANGE_PHONE,
        payload: e
    });
};

export const changeNote = e => dispatch => {
    dispatch({
        type: CHANGE_NOTE,
        payload: e
    });
};

export const changeMarketing = e => dispatch => {
    dispatch({
        type: CHANGE_MARKETING,
        payload: e
    });
};

export const changeFidelity = e => dispatch => {
    dispatch({
        type: CHANGE_FIDELITY,
        payload: e
    })
}
export const handleHasFidelity = e => dispatch => {
    dispatch({
        type: HAS_FIDELITY,
        payload: e
    });
};

export const CheckFidelity = idCard => dispatch => {
    const RESULT = [1, "Tessera esistente"];
    dispatch({
        type: CHECK_FIDELITY,
        payload: RESULT
    });
}

export const handleDisableButton = (x) => dispatch => {
    dispatch({
        type: DISABLE_BUTTON,
        payload: x
    });
}
export const handleOpenDialog = (x) => dispatch => {
    dispatch({
        type: OPEN_DIALOG,
        payload: x
    });
}
export const handleAcceptTermini = (x) => dispatch => {
    dispatch({
        type: ACCEPT_TERMINI,
        payload: x
    });
}

export const validateAction = (validate, input) => dispatch => {
    dispatch({
        type: VALIDATE,
        payload: { validate, input }
    });
}
export const errorAction = (errors, input) => dispatch => {
    dispatch({
        type: ERROR,
        payload: { errors, input }
    });
}

export const selectCreditCardTypesAction = e => dispatch => {
    dispatch({
        type: CHANGE_IDCREDITCARD,
        payload: e
    });
};
export const changeCardHolderNameAction = e => dispatch => {
    dispatch({
        type: CHANGE_CARDHOLDERNAME,
        payload: e
    })
}
export const changeExpirationDateAction = e => dispatch => {
    dispatch({
        type: CHANGE_EXPIRATIONDATE,
        payload: e
    })
}
export const changeCardNumberAction = e => dispatch => {
    dispatch({
        type: CHANGE_CARDNUMBER,
        payload: e
    })
}
export const changeSecurityCode = e => dispatch => {
    dispatch({
        type: SECURITY_CODE,
        payload: e
    })
}
export const DistanziamentoAction = (x)  => dispatch => {
    dispatch({
        type: DISTANZIAMENTO,
        payload: x
    })
}

