import React, { Component } from 'react'

import GalleryRoom from '../GalleryRoom';
import Rooms from "../Rooms/Rooms";
import BookingDate from "../BookingDate/BookingDate";
import BookingTime from "../BookingTime/BookingTime";
import Person from "../Person/Person";
import UserDetails from "../UserDetails/UserDetails";
import CreditCard from "../CreditCard/CreditCard";
import Confirm from "../Confirm/Confirm";
import Success from "../Success/Success";

export class Step extends Component {

    renderSwitch() {
        const {step} = this.props
        switch (step) {

          case 1:
            return <BookingDate />
          case 2:
            return <Rooms />
          case 3:
            return <GalleryRoom />
          case 4:
            return <BookingTime />
          case 5:
            return <Person />
          case 6:
            return <UserDetails />
          case 7:
            return <CreditCard />
          case 8:
            return <Confirm />
          case 9:
            return <Success />
          default:
            return <BookingDate />;
        }
    }

    render() {
        return (
            <>
                {this.renderSwitch()} 
            </>
        )
    }
}

export default Step
