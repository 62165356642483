import React, { Component } from 'react'
import IntlMessages from '../../helpers/IntlMessages';

import { connect } from "react-redux";
import { selectAdults, selectChildren, selectSeggiolone } from "../../actions/";

import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MuiAlert from "@material-ui/lab/Alert";
import ChildFriendlyOutlinedIcon from '@material-ui/icons/ChildFriendlyOutlined';
import Typography from '@material-ui/core/Typography';

import { Grid } from '@material-ui/core'
import styles from '../../styles/styles'

export class Person extends Component {
         handleChangeAdults = (e) => {
            let { value, maxLength } = e.target;
            if (value.charAt(0) === "0") {
              return value = "1";
            }            
            const number = value.slice(0, maxLength);
            this.props.selectAdults(number)
          }
          handleChangeChildren = (e) => {
            let { value, maxLength } = e.target;
            if (value < this.props.person.seggiolone){
              this.props.selectSeggiolone(value);
            }
            let number = value.slice(0, maxLength);
            this.props.selectChildren(number);
          }
          handleChangeSeggiolone = (e) => {
            let { value, maxLength, max } = e.target;
            if (value > max) { value = max }
            let number = value.slice(0, maxLength);
            this.props.selectSeggiolone(number);
          }
         render() {
           const {
             errors,
             person,
             errorsChild,
             errorsSeggioloni,
             seggioloni
           } = this.props;
           function Alert(props) {
             return <MuiAlert elevation={6} variant="filled" {...props} />;
           }
           return (
             <Grid container spacing={2}>
               <Grid item xs={6}>
                 <FormControl variant="outlined" style={styles.input}>
                   <OutlinedInput
                     type="number"
                     name={"adults"}
                     value={person.adults}
                     onChange={e => this.handleChangeAdults(e)}
                     inputProps={{
                       min: "1",
                       maxLength: "1"
                     }}
                     endAdornment={
                       <InputAdornment position="end">                         
                         <IntlMessages 
                          id="input.label.adults" 
                          values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>
                          }}
                        />
                        </InputAdornment>
                     }
                     error={errors ? true : false}
                   />
                 </FormControl>
               </Grid>
               <Grid item xs={6}>
                 <FormControl variant="outlined" style={styles.input}>
                   <OutlinedInput
                     type="number"
                     name={"children"}
                     value={person.children}
                     onChange={e => this.handleChangeChildren(e)}
                     inputProps={{ min: "0", maxLength: "1" }}
                     endAdornment={
                       <InputAdornment position="end">                         
                         <IntlMessages 
                          id="input.label.children" 
                          values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>
                          }}
                        />
                        </InputAdornment>
                     }
                     error={errors || errorsChild ? true : false}
                   />
                   {errorsChild ? (
                     <Alert severity="error">{errorsChild}</Alert>
                   ) : (
                     ""
                   )}
                 </FormControl>
               </Grid>
               {person.children > 0 && seggioloni > 0 ? (
              <>
                <Grid item xs={12} md={6} justify="center" alignItems="center">
                     <Typography variant="caption" display="block" gutterBottom style={styles.caption}>
                       <IntlMessages 
                          id="input.label.seggioloni.add" 
                          values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>
                          }}
                        />
                      </Typography>
               </Grid>
                   <Grid item xs={12} md={6} >
                     {
                       // eslint-disable-next-line
                     }<FormControl variant="outlined" style={styles.input, { marginTop: 0, width: "100%" }}>
                   <OutlinedInput
                     type="number"
                     name={"seggiolone"}
                     value={person.seggiolone}
                     onChange={e => this.handleChangeSeggiolone(e)}
                    inputProps={{ min: "0", maxLength: "1", max: person.children}}
                     endAdornment={
                       <InputAdornment position="end">                         
                         <IntlMessages 
                          id="input.label.seggioloni" 
                          values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>
                          }}
                        />
                       </InputAdornment>
                     }
                    startAdornment={
                        <InputAdornment position="start">
                          <ChildFriendlyOutlinedIcon />
                        </InputAdornment>
                    }
                     error={errors || errorsChild ? true : false}
                   />
                    {errorsSeggioloni ? (
                         <Alert severity="error">{errorsSeggioloni}</Alert>
                   ) : (
                       ""
                     )}
                 </FormControl>
               </Grid>
               </>
               ) : ""}
               <Grid item xs={12}>
                 {errors ? <Alert severity="error">{errors}</Alert> : ""}
               </Grid>
             </Grid>
           );
         }
       }

const mapStateToProps = (state) => {
  return {
    room: state.reservation.room,
    totalSeats: state.totalSeats,
    peopleSeats: state.peopleSeats,
    childrenSeats: state.childrenSeats,
    seggioloni: state.seggioloni,
    availableTimeSlots: state.availableTimeSlots,
    time: state.reservation.time,
    person: {
      adults: state.reservation.person.adults,
      children: state.reservation.person.children,
      seggiolone: state.reservation.person.seggiolone,
    },
    errors: state.errors.person,
    errorsChild: state.errors.children,
    errorsSeggioloni: state.errors.seggiolone
  };
}

export default connect(
  mapStateToProps,
  { selectAdults, selectChildren, selectSeggiolone }
)(Person);
