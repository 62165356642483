import React from 'react';
import { useSelector } from 'react-redux';
import AppLocale from './lang';
import { IntlProvider } from 'react-intl';

import { Form } from './components/Form/Form';

function App() {

  const {locale} = useSelector((state) => state.settings)
  const currentAppLocale = AppLocale[locale];

  return (
    <div className="App">
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Form />
      </IntlProvider>
    </div>
  );
}

export default App;
