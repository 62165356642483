import Fidelity from "../apis/Fidelity";
import {
    GET_CREDITCARD,
    ERROR,
} from "../const";
import xml2js from "xml2js";


export const GetCreditCardTypes = () => async dispatch => {

    let body = `<?xml version="1.0" encoding="utf-8"?>
                <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                <soap:Body>
                    <GetCreditCardTypes xmlns="http://tempuri.org/" />
                </soap:Body>
                </soap:Envelope>`;

    const promise = await Fidelity.post("", body, {
        params: {
            op: "GetCreditCardTypes"
        }
    });

    var parser = new xml2js.Parser();

    parser.parseStringPromise(promise.data)
        .then(function(result) {

            const data =
                result["soap:Envelope"]["soap:Body"][0]
                .GetCreditCardTypesResponse[0][
                    "GetCreditCardTypesResult"
                ][0]["CreditCartTypes"][0]["CreditCardType"];

            dispatch({
                type: GET_CREDITCARD,
                payload: data
            })
        })
        .catch(function(err) {
            let input = "creditcard";
            dispatch({
                type: ERROR,
                payload: { err, input }
            })
        });
};