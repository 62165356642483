/* GN Language Texts

Table of Contents

Button
Input Label

*/

module.exports = {
    /* Ok Message */    
    'ok.message.room.selected':'Hai selezionato <br>\n</br><br>\n</br>{room}',
    'marketing':'Presto il consenso per le finalità di marketing previste al punto 1.3 dell\'',
    'marketingLink':'informativa sulla privacy',
    'fidelity':'Possiede già una tessera fidelity di QVINTO?',
    'termsCondition':'Termini e Condizioni (Politica dei Ritardi)',
    'confirmation.label.ok':'Prenotazione Confermata!',
    'confirmation.label.sendMail':'Abbiamo inviato una email di riepilogo all\'indirizzo {email}',
    'confirmation.label.sendMail.attention':'<b>Attenzione: in assenza di email ed sms di conferma della prenotazione la stessa non si intende valida.</b> ',
    'confirmation.label.sala':'Sala: <b>{room}</b>',
    'confirmation.verify.data':' Verificare i Dati e Accetta i Termini',
    /* Button */    
    'button.back.room.selection': 'Torna a selezione sala',
    'button.proceed': 'Procedi',
    'button.next':'Continua',
    'button.back':'Indietro',
    /* Input Label */ 
    'input.label.firstName': 'Nome',
    'input.label.lastName': 'Cognome',
    'input.label.phone': 'Cellulare',
    'input.label.email': 'Email',
    'input.label.fidelity': 'Numero Fidelity',
    'input.label.room': 'Sala',
    'input.label.data': 'Data',
    'input.label.time': 'Orari disponibili',
    'input.label.time2': 'Orario',
    'input.label.adults': 'Adulti',
    'input.label.children': 'Bambini',
    'input.label.seggioloni.add': 'Aggiungi un seggiolone per il tuo bambino',
    'input.label.seggioloni': 'Seggioloni',
    'input.label.privacy': 'Privacy Marketing',
    'input.label.privacy.ok': 'Ho letto ed accettato i {link} per accedere al locale',
    'input.label.privacy.ok.link': 'Termini e le Condizione',
    'input.label.privacy.ok.linkCovid': 'Norme di Sicurezza',
    /* Info Help */ 
    'input.help.room': 'Seleziona una sala', //da rivedere il modo di passare intl al middelware
    'input.help.timing': 'Attenzione: per la sala selezionata la permanenza massima è di <b>1 ora e 45 minuti</b>', 
    /* Description room */ 
    'room.typology':'Tipologia sala: ',
    /* Id 1 Lemon Room */ 
    'room.typology.1':'Interna',
    'room.adults.1':'',    
    'room.season.1':'Disponibile sia nella stagione <b>estiva</b> che in quella <b>invernale</b>',
    'room.description.1':'La Lemon Room è la sala interna del ristorante dove è possibile gustare tutta la ricca proposta culinaria di Qvinto, assaporando tutti i primi e i piatti della nostra cucina, scegliendo le carni alla brace, i piatti della tradizione romana, la nostra pizza napoletana, i fritti, fantastici dessert e tanti drink esclusivi firmati dai nostri bartender.',
    /* Id 2 Cool Garden 2*/ 
    'room.typology.2':'Esterna',
    'room.adults.2':'All’interno della location non è consentito l’accesso ai minorenni',    
    'room.season.2':'Disponibile solo nella stagione <b>estiva</b>',
    'room.description.2':'Nel nostro esclusivo e suggestivo dehor estivo è possibile gustare le nostre colazioni tradizionali ed internazionali, assaporare gustosi aperitivi, pranzare e cenare scegliendo tra una vasta offerta di carni alla  brace, antipasti, fritti e pizza napoletana, gustare tutti i nostri dessert e tanti drink esclusivi firmati dai nostri bartender.',
    /* Id 3 The Roof */ 
    'room.typology.3':'Interna',
    'room.adults.3':'',    
    'room.season.3':'Disponibile sia nella stagione <b>estiva</b> che in quella <b>invernale</b>',
    'room.description.3':'La suggestiva <b>terrazza di Qvinto Restaurant</b> è perfetta per godere del panorama del parco grazie alle ampie vetrate e al natural interior design.<br>\n</br><br>\n</br>Nella nostra terrazza potrai goderti un drink unico preparato dai nostri bartender professionisti, lasciandoti coccolare dall\’atmosfera speciale di un elegante e confortevole salotto, all\'interno del quale poter gustare uno <b>speciale menù</b> realizzato appositamente per gli ospiti di questa location unica.<br>\n</br><br>\n</br>Le nostre proposte per le serate al THE ROOF prevedono per la cena le nuove pizze preparate dai nostri mastri pizzaioli, accompagnate dalle più pregiate “bollicine” selezionate dai nostri sommelier. Per l’aperitivo non perdere gli eccezionali appetizer gourmet realizzati dagli Chef di Qvinto e i drink dei nostri barman.<br>\n</br><br>\n</br>La terrazza di Qvinto, non si limita a gustose food experience e ad un\'offerta mixologica eccezionale... <b>performance uniche, musica live, pianobar</b> e tantissimi <b>eventi esclusivi</b> ti aspettano ogni sera.<br>\n</br><br>\n</br>Le tue notti romane non saranno più le stesse.<br>\n</br><br>\n</br><b>Per accedere alla location è necessario il Green Pass</b><br>\n</br><br>\n</br><b>All’interno dell’area non è consentito l’accesso ai minorenni dopo le ore 18:00</b>',
    /* Id 4 Lounge Bar */ 
    'room.typology.4':'Interna',
    'room.adults.4':'',    
    'room.season.4':'Disponibile sia nella stagione <b>estiva</b> che in quella <b>invernale</b>',
    'room.description.4':'Nel nostro elegante Lounge Bar potrai gustare le ricche colazioni tradizionali ed internazionali targate Qvinto, assaporare un aperitivo e gustare gli incredibili drink realizzati dai nostri bartender e gli eccezionali dolci targati Qvinto Restaurant. Nel Lounge bar sono presenti postazioni smart per ricaricare i tuoi dispositivi e una connessione veloce ad internet per vivere le tue giornate di smart working in modo “different”.',
    /* Id 6 Cristal Room */ 
    'room.typology.6':'Esterna/Coperta',
    'room.adults.6':'',    
    'room.season.6':'Disponibile sia nella stagione <b>estiva</b> che in quella <b>invernale</b>',
    'room.description.6':'Nella nostra esclusiva veranda è possibile gustare tutta la ricca proposta culinaria di Qvinto, assaporando tutti i primi e i piatti della nostra cucina, scegliendo le carni alla brace, i piatti della tradizione romana, la nostra pizza napoletana, i fritti, fantastici dessert e tanti drink esclusivi firmati dai nostri bartender.',
    /* Id 7 Secret Garden */ 
    'room.typology.7':'Esterna',
    'room.adults.7':'',    
    'room.season.7':'Disponibile sia nella stagione <b>estiva</b> che in quella <b>invernale</b>',
    'room.description.7':'Il nostro Secret Garden, unico per il suo esclusivo “soffitto di fiori”, offre un ambiente intimo e riservato con tavoli esclusivamente da 2 persone, per vivere un’esperienza all’insegna del romanticismo. Nel Secret Garden è possibile gustare a pieno tutta l’offerta culinaria di Qvinto, con un ricco menù di primi piatti, una vasta proposta di carni alla brace, piatti della tradizione romana, pizza napoletana, fritti, dessert e tanti drink esclusivi firmati dai nostri bartender.',
    /* Id 8 Jungle Garden */ 
    'room.typology.8':'Esterna',
    'room.adults.8':'All’interno della location non è consentito l’accesso ai minorenni',    
    'room.season.8':'Disponibile solo nella stagione <b>estiva</b>',
    'room.description.8':'Esplora un angolo di giungla suggestivo ed emozionante! Nella giungla tropicale di Qvinto potrai gustare a pieno la ricca proposta culinaria di Qvinto, a partire dalle nostre colazioni tradizionali ed internazionali, assaporare un gustoso aperitivo, pranzare e cenare scegliendo tra una vasta offerta di carni alla brace, antipasti, fritti e pizza napoletana, gustare i nostri dessert e tanti drink esclusivi firmati dai nostri bartender.',
    /* Id 9 Jungle Cube */ 
    'room.typology.9':'Esterna',
    'room.adults.9':'All’interno della location non è consentito l’accesso ai minorenni',    
    'room.season.9':'Disponibile solo nella stagione <b>estiva</b>',
    'room.description.9':'Parte integrante del nostro Jungle Garden sono i nostri fantastici privè Jungle Cube, una location speciale e dedicata a chi vuole vivere un’esperienza esclusiva in un ambiente intimo e riservato, in perfetto stile “tropical”.  Nei Jungle Cube potrai gustare tutta la ricca proposta culinaria di Qvinto, assaporando tutti i primi e i piatti della nostra cucina, scegliendo le carni alla brace, i piatti della tradizione romana, la nostra pizza napoletana, i fritti, fantastici dessert e tanti drink esclusivi firmati dai nostri bartender.',
    /* Id 12 Igloo */ 
    'room.typology.12':'Esterna/Coperta',
    'room.adults.12':'',    
    'room.season.12':'Disponibile solo nella stagione <b>invernale</b>',
    'room.description.12':'Il nostro magico giardino invernale è composto da 16 incredibili Igloo, coperti, riscaldati e perfettamente arredati a tema. <br>\n</br><br>\n</br>All’interno degli Igloo è possibile gustare le nostre colazioni tradizionali ed internazionali, assaporare un gustoso aperitivo, pranzare e cenare scegliendo tra una vasta offerta di carni alla  brace, antipasti, fritti e pizza napoletana, oltra alla ricca carta dei nostri dessert e tanti drink esclusivi firmati dai nostri bartender.',
     /* Id 14 Apollo */ 
     'room.typology.14':'Coperta',
     'room.adults.14':'',    
     'room.season.14':'Disponibile solo nella stagione <b>invernale</b>',
     'room.description.14':'',
    /* Id 15 Notting Hill */ 
    'room.typology.15':'Esterna',
    'room.adults.15':'All’interno della location non è consentito l’accesso ai minorenni',    
    'room.season.15':'Disponibile solo nella stagione <b>estiva</b>',
    'room.description.15':'Da uno dei quartieri più colorati ed eleganti di Londra arriva la novità dell\'estate 2021 targata Qvinto: Notting hill! Il nostro prestigioso giardino british style dove potrai vivere la classica atmosfera londinese “all day long”, dalla colazione fino alla cena, gustando a pieno la completa offerta culinaria di Qvinto, a partire dai primi e dai piatti della nostra cucina, scegliendo tra la vasta offerta delle nostre carni alla brace, gustare la vera pizza napoletana, tantissimi dessert, drink esclusivi e tanto altro ancora!',
   /* Id 16 Cool Garden 1 */ 
   'room.typology.16':'Esterna',
   'room.adults.16':'All\'interno della location non è consentito l\'accesso ai minorenni dopo le 18:00',    
   'room.season.16':'Disponibile solo nella stagione <b>estiva</b>',
   'room.description.16':'Salotto d\'ingresso presente nel nostro esclusivo dehor estivo dov\'è possibile gustare le nostre colazioni tradizionali ed internazionali, assaporare gustosi aperitivi, pranzare e cenare scegliendo tra una vasta offerta di carni alla brace, antipasti, fritti e pizza napoletana, gustare tutti i nostri dessert e tanti drink esclusivi realizzati dai nostri bartender.',
 };