import React, {Component} from 'react';
import IntlMessages from '../../helpers/IntlMessages';

import {connect} from 'react-redux';
import {
    handleDisableButton,
    handleAcceptTermini,
    handleOpenDialog
} from '../../actions/';

import {EventAvailable, AccessTime, Person} from '@material-ui/icons';
import {Grid, FormControlLabel, Typography, Switch, Link} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import moment from 'moment';
import styles from '../../styles/styles';

import TerminiCondizioni from '../TerminiCondizioni/TerminiCondizioni';

export class Confirm extends Component {
    componentDidMount = () => {
        this.props.handleDisableButton(true);
    };
    handleClose = (e) => {
        e.preventDefault();
        this.props.handleOpenDialog(false);
    };
    handleOpen = (e) => {
        e.preventDefault();
        this.props.handleOpenDialog(true);
    };

    render() {
        const {
            reservation,
            user,
            handleAcceptTermini,
            terminiCondizioni,
            DistanziamentoAction,
            distanziamentoSociale,
            errors,
            room
        } = this.props;

        let MarketinAccettato;
        if (user.marketing) {
            MarketinAccettato = 'Accettata';
        } else {
            MarketinAccettato = 'Non Accettata';
        }

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        return (
            <>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Typography align="center" color="primary" variant="h5">
                        <IntlMessages
                            id="confirmation.label.sala"
                            values={{
                                b: (...chunks) => <b>{chunks}</b>,
                                br: (chunks) => <><br/>{chunks}</>,
                                room: `${reservation.roomName}`
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Typography
                        align="left"
                        color="primary"
                        variant="h6"
                        style={styles.h6}
                    >
                        <EventAvailable color="primary" style={styles.icon}/>
                        {moment(reservation.date, 'YYYY-MM-DD').format('LL')}
                    </Typography>
                    <Typography
                        align="left"
                        color="primary"
                        variant="h6"
                        style={styles.h6}
                    >
                        <Person color="primary" style={styles.icon}/>
                        {Number(reservation.person.adults) +
                            Number(reservation.person.children)}
                    </Typography>
                    <Typography
                        align="left"
                        color="primary"
                        variant="h6"
                        style={styles.h6}

                    >
                        <AccessTime color="primary" style={styles.icon}/>
                        {reservation.time}
                    </Typography>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginBottom: '1rem'}}
                >
                    {reservation.room === 3 || reservation.room === 8 ? (
                        <Alert severity="error">
                            <Typography
                                align="center"
                                color="contrastText"
                                variant="p"
                            >
                                <IntlMessages
                                    id="input.help.timing"
                                    values={{
                                        b: (...chunks) => <b>{chunks}</b>,
                                        br: (chunks) => <><br/>{chunks}</>
                                    }}
                                />
                            </Typography>
                        </Alert>
                    ) : (
                        ''
                    )}

                    <Typography align="center" color="primary" variant="h5">
                        <IntlMessages
                            id="confirmation.verify.data"
                            values={{
                                b: (...chunks) => <b>{chunks}</b>,
                                br: (chunks) => <><br/>{chunks}</>
                            }}
                        />
                    </Typography>
                </Grid>
                {/* <Grid
                 container
                 spacing={2}
                 direction="row"
                 justify="center"
                 alignItems="center"
                 style={{ padding: "0.5rem" }}
               >
                 <Grid item xs={6}>
                   <Typography align="left" variant="body1">
                     <IntlMessages 
                      id="input.label.fidelity" 
                      values={{
                        b: (...chunks) => <b>{chunks}</b>,
                        br: (chunks) => <><br />{chunks}</>
                      }}
                    />:   
                   </Typography>
                 </Grid>
                 <Grid item xs={6}>
                   <Typography
                     align="right"
                     variant="body1"
                     style={{ fontWeight: "bold" }}
                   >
                     {user.fidelity}
                   </Typography>
                 </Grid>
               </Grid> */}
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.data"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {moment(reservation.date, 'YYYY-MM-DD').format('LL')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.time2"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {reservation.time}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.adults"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {reservation.person.adults}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.children"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {reservation.person.children}
                            {reservation.person.seggiolone > 0 ? ' (' + reservation.person.seggiolone + ' Seggiolone)' : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.firstName"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {user.firstName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.lastName"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {user.lastName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.phone"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {user.phone}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.email"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {user.email}
                        </Typography>
                    </Grid>
                </Grid>
                {/* <Grid
                 container
                 spacing={2}
                 direction="row"
                 justify="center"
                 alignItems="center"
                 style={{ padding: "0.5rem" }}
               >
                 <Grid item xs={12}>
                   <Typography align="left" variant="body1">
                     Note:
                   </Typography>
                 </Grid>
                 <Grid item xs={12}>
                   <Typography
                     align="left"
                     variant="body1"
                     style={{ fontWeight: "bold" }}
                   >
                     {user.note}
                   </Typography>
                 </Grid>
               </Grid> */}
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{padding: '0.5rem'}}
                >
                    <Grid item xs={6}>
                        <Typography align="left" variant="body1">
                            <IntlMessages
                                id="input.label.privacy"
                                values={{
                                    b: (...chunks) => <b>{chunks}</b>,
                                    br: (chunks) => <><br/>{chunks}</>
                                }}
                            />:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align="right"
                            variant="body1"
                            style={{fontWeight: 'bold'}}
                        >
                            {MarketinAccettato}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Typography>
                        <IntlMessages
                            id="input.label.privacy.ok"
                            values={{
                                b: (...chunks) => <b>{chunks}</b>,
                                br: (chunks) => <><br/>{chunks}</>,
                                link: <Link href="#" onClick={e => this.handleOpen(e)}><IntlMessages
                                    id="input.label.privacy.ok.link"/></Link>
                            }}
                        />
                    </Typography>
                    <Switch
                        checked={terminiCondizioni}
                        onChange={x => handleAcceptTermini(x.target.checked)}
                        color="primary"
                    />
                    <TerminiCondizioni
                        open={e => this.handleOpen(e)}
                        handleClose={e => this.handleClose(e)}
                    />
                </Grid>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        reservation: {
            room: state.reservation.room,
            roomName: state.reservation.roomName,
            date: state.reservation.date,
            time: state.reservation.time,
            person: {
                adults: state.reservation.person.adults,
                children: state.reservation.person.children,
                seggiolone: state.reservation.person.seggiolone
            }
        },
        user: {
            fidelity: state.user.fidelity,
            firstName: state.user.firstName,
            lastName: state.user.lastName,
            email: state.user.email,
            phone: state.user.phone,
            note: state.user.note,
            marketing: state.user.marketing
        },
        rooms: state.rooms,
        terminiCondizioni: state.terminiCondizioni,
        openDialog: state.openDialog,
        distanziamentoSociale: state.distanziamentoSociale,
        errors: state.errors,

    };
};

export default connect(
    mapStateToProps,
    {handleDisableButton, handleAcceptTermini, handleOpenDialog}
)(Confirm);
