/* GN Language Texts

Table of Contents

Button
Input Label

*/

module.exports = {
      /* Ok Message */    
      'ok.message.room.selected':'You have selected <br>\n</br>{room}',
      'marketing':'I give my consent for the marketing purposes set out in point 1.3 of the',
      'marketingLink':'Privacy Policy',
      'fidelity':'Do you already have a QVINTO loyalty card?',
      'termsCondition':'Terms and Conditions (Delay Policy)',
      'confirmation.label.ok':'Reservation confirmed!',
      'confirmation.label.sendMail':'We have sent a summary email to {email}',
      'confirmation.label.sendMail.attention':'<b> Attention: in the absence of e-mails and sms confirming the reservation, the reservation is not considered valid. </b>',
      'confirmation.label.sala':'Room: <b> {room} </b>',
      'confirmation.verify.data':' Check the data and accept the terms',
      /* Button */    
      'button.back.room.selection': 'Back to room selection',
      'button.proceed': 'Proceed',
      'button.next':'Next',
      'button.back':'Back',
     /* Input Label */ 
     'input.label.firstName': 'Name',
     'input.label.lastName': 'Surname',
     'input.label.phone': 'Mobile phone',
     'input.label.email': 'Email',
     'input.label.fidelity': 'Fidelity number',
     'input.label.room': 'Room',
     'input.label.data': 'Date',
     'input.label.time': 'Available times',
     'input.label.time2': 'Schedule',
     'input.label.adults': 'Adults',
     'input.label.children': 'Children',
     'input.label.seggioloni.add': 'Add a high chair for your baby',
     'input.label.seggioloni': 'High chairs',
     'input.label.privacy': 'Privacy Marketing',
     'input.label.privacy.ok': 'I have read and accepted the {link} and {linkCovid} to access the club',
     'input.label.privacy.ok.link': 'Terms and Conditions',
     'input.label.privacy.ok.linkCovid': 'Safety standards',
     /* Info Help */ 
     'input.help.room': 'Select a room', //da rivedere il modo di passare intl al middelware
     'input.help.timing': 'Attention: for the selected room the maximum stay is <b> 1 hour and 45 minutes </b>', 
     /* Description room */ 
     'room.typology':'Room type: ',
     /* Id 1 Lemon Room */ 
     'room.typology.1':'Indoor',
     'room.adults.1':'',    
     'room.season.1':'Available in both <b>summer</b> and <b>winter</b> seasons ',
     'room.description.1':'The Lemon Room is the inner room of the restaurant where you can enjoy all the rich culinary proposal of Qvinto, tasting all the first courses and dishes of our cuisine, choosing grilled meats, traditional Roman dishes, our Neapolitan pizza, fried food, fantastic desserts and many exclusive drinks signed by our bartender.',
     /* Id 2 Cool Garden */ 
     'room.typology.2':'Outdoor',
     'room.adults.2':'The access to the location is reserved to over 18',    
     'room.season.2':'Available only in the <b>summer</b> season',
     'room.description.2':'In our exclusive and suggestive summer dehor you can enjoy our traditional and international breakfasts, savour tasty aperitifs, have lunch and dinner choosing from a wide range of grilled meats, appetizers, fried food and Neapolitan pizza, taste all our desserts and many exclusive drinks created by our bartender.',
     /* Id 3 The Roof */ 
     'room.typology.3':'Indoor',
     'room.adults.3':'',    
     'room.season.3':'Available in both <b>summer</b> and <b>winter</b> seasons ',
     'room.description.3':'Our exclusive terrace is the perfect location to enjoy an aperitif and lunch or dinner, choosing from a wide range of grilled meats, appetisers, fried food and Neapolitan pizza, as well as our extensive dessert menu and sensational drinks made by our bartenders.',
     /* Id 4 Lounge Bar */ 
     'room.typology.4':'Indoor',
     'room.adults.4':'',    
     'room.season.4':'Available in both <b>summer</b> and <b>winter</b> seasons ',
     'room.description.4':'In our elegant Lounge Bar you can enjoy the rich traditional and international breakfasts by Qvinto, enjoy an aperitif and taste the incredible drinks made by our bartenders and the exceptional desserts by Qvinto Restaurant. In the Lounge Bar there are smart stations to recharge your devices and a fast internet connection to live your smart working days in a "different" way.',
     /* Id 6 Cristal Room */ 
     'room.typology.6':'Outdoor/covered',
     'room.adults.6':'',    
     'room.season.6':'Available in both <b>summer</b> and <b>winter</b> seasons ',
     'room.description.6':'In our exclusive veranda you can enjoy all the rich culinary offer of Qvinto, savouring all the first courses and dishes of our cuisine, choosing from grilled meats, traditional Roman dishes, our Neapolitan pizza, fried food, fantastic desserts and many exclusive drinks signed by our bartender',
     /* Id 7 Secret Garden */ 
     'room.typology.7':'Outdoor',
     'room.adults.7':'',    
     'room.season.7':'Available in both <b>summer</b> and <b>winter</b> seasons ',
     'room.description.7':'Our Secret Garden, unique for its exclusive "flower ceiling", offers an intimate and reserved environment with tables for only 2 people, for a romantic experience. In the Secret Garden you can fully enjoy all the Qvinto\'s culinary offer, with a rich menu of first courses, a wide range of grilled meats, traditional Roman dishes, Neapolitan pizza, fried food, desserts and many exclusive drinks signed by our bartenders.',
     /* Id 8 Jungle Garden */ 
     'room.typology.8':'Outdoor',
     'room.adults.8':'The access to the location is reserved to over 18',    
     'room.season.8':'Available only in the <b>summer</b> season',
     'room.description.8':'Explore an evocative and exciting corner of the jungle! In the tropical jungle of Qvinto you can fully enjoy Qvinto\'s rich culinary offer, starting with our traditional and international breakfasts, enjoy a tasty aperitif, lunch and dinner choosing from a wide range of grilled meats, appetisers, fried food and Neapolitan pizza, taste our desserts and many exclusive drinks signed by our bartenders.',
     /* Id 9 Jungle Cube */ 
     'room.typology.9':'Outdoor',
     'room.adults.9':'The access to the location is reserved to over 18',    
     'room.season.9':'Available only in the <b>summer</b> season',
     'room.description.9':'An integral part of our Jungle Garden are our fantastic Jungle Cube privè, a special location dedicated to those who want to live an exclusive experience in an intimate and reserved environment, in perfect "tropical" style.  In the Jungle Cube you will be able to taste all the rich Qvinto’s culinary offer, savouring all the first courses and dishes of our cuisine, choosing grilled meats, traditional Roman dishes, our Neapolitan pizza, fried food, fantastic desserts and many exclusive drinks signed by our bartenders.',
     /* Id 14 Igloo */ 
     'room.typology.12':'Outdoor/covered',
     'room.adults.12':'',    
     'room.season.12':'Only available in the <b>winter</b> season',
     'room.description.12':'Our magical winter garden consists of 16 incredible Igloos, covered, heated and perfectly themed. <br>\n</br>Inside the Igloos you can enjoy our traditional and international breakfasts, savour a tasty aperitif, have lunch and dinner choosing from a wide range of grilled meats, appetisers, fried food and Neapolitan pizza, as well as our extensive dessert menu and many exclusive drinks created by our bartenders.',
     /* Id 15 Notting Hill */ 
     'room.typology.15':'Outdoor',
     'room.adults.15':'The access to the location is reserved to over 18',    
     'room.season.15':'Available only in the <b>summer</b> season',
     'room.description.15':'From one of the most colourful and elegant districts of London comes the new Qvinto summer 2021: Notting hill! Our prestigious British style garden where you can experience the classic London atmosphere "all day long", from breakfast to dinner, fully enjoying the complete Qvinto culinary offer, starting from the first courses and dishes of our kitchen, choosing from the wide range of our grilled meats, enjoying the real Neapolitan pizza, many desserts, exclusive drinks and much more!',     
      /* Id 16 Cool Garden 1 */ 
      'room.typology.16':'Outdoor',
      'room.adults.16':'The access inside the location is reserved to over 18 after 6:00 PM',    
      'room.season.16':'Available only in the <b>summer</b> season',
      'room.description.16':'The Entrace lounge in our exclusive and suggestive summer dehor where you can enjoy our traditional and international breakfasts, savour tasty aperitif, have lunch and dinner choosing form a wide range of grilled meats, appetizers, fired food and Napolitan pizza, taste all our desserts and many exclusive drinks created by our bartender.',
};