import React, { Component } from 'react'
import Cards from 'react-credit-cards';

import { connect } from "react-redux";
import {
    GetCreditCardTypes,
} from "../../actions/GetCreditCardTypes";
import {
    selectCreditCardTypesAction,
    changeCardHolderNameAction,
    changeExpirationDateAction,
    changeCardNumberAction,
    changeSecurityCode,
    handleDisableButton,
    nextStep
} from "../../actions/";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import TextField from '@material-ui/core/TextField';
import styles from '../../styles/styles';
import MuiAlert from "@material-ui/lab/Alert";


export class CreditCard extends Component {
        state = {
            focus: '',
        };

        componentDidMount = () =>{
            if (this.props.CreditCardBooking === "true") {
                this.props.GetCreditCardTypes();
            } else {
                this.props.nextStep();
            }
        }

        handleInputFocus = (e) => {
            this.setState({ focus: e.target.name });
        }

        handleInputChange = (e) => {
            const { name, value } = e.target;
            switch (name) {
                case "number":
                    this.props.changeCardNumberAction(value);
                    break;
                case "name":
                    this.props.changeCardHolderNameAction(value);
                    break;
                case "expiry":
                    let expiryDateFormat;
                    let len = value.length;
                    if (len === 2) {
                        expiryDateFormat = value.slice(0, 2) + "/" + value.slice(2, len);
                        this.props.changeExpirationDateAction(expiryDateFormat);
                    } else {
                        this.props.changeExpirationDateAction(value);
                    }
                    break;
                case "cvc":
                    this.props.changeSecurityCode(value);
                    break;
                default:
                    this.props.changeCardNumberAction(value);
            }
        }

        handleChangeCreditCardTypes = e => {
            this.props.selectCreditCardTypesAction(e.target.value);
        }

        NumberFormatCustom = (props) => {
            const { inputRef, onChange, ...other } = props;

            return (
                <NumberFormat
                    {...other}
                    getInputRef={inputRef}
                    onValueChange={(values) => {
                        onChange({
                            target: {
                                name: props.name,
                                value: values.value,
                            },
                        });
                    }}                    
                    isNumericString
                />
            );
        }

         render() {

            const {
                CreditCartTypes,
                creditCard,
                errors,
                validate,
                handleDisableButton
            } = this.props;

             if (
                 validate.IdCreditCardType &&
                 validate.CardholderName &&
                 validate.ExpirationDate &&
                 validate.CardNumber &&
                 validate.SecurityCode
             ) {
                 handleDisableButton(false);
             } else {
                 handleDisableButton(true);
             }

             let listaCarteDiCredito;

             if (CreditCartTypes) {
                
                listaCarteDiCredito = CreditCartTypes.map(type => (
                    <MenuItem
                        value={type["Id"][0]}
                        data-name={type["Description"][0]}
                        key={type["Id"][0]}
                        selected={type["Description"][0] === creditCard.IdCreditCardType}
                    >
                        {type["Description"][0]}
                    </MenuItem>
                ));
            }

             function Alert(props) {
                 return <MuiAlert elevation={6} variant="filled" {...props} />;
             }

           return (
             <>
                <Cards
                    cvc={creditCard.SecurityCode}
                    expiry={creditCard.ExpirationDate}
                    focused={this.state.focus}
                    name={creditCard.CardholderName}
                    number={creditCard.CardNumber}
                    placeholders={{name: 'NOME COGNOME' }}
                />
                
                <form noValidate autoComplete="off" style={styles.formControl}>
                 <Grid
                   container
                   direction="row"
                   justify="center"
                   alignItems="center"
                   spacing={1}
                 >
                    <Grid item xs={12}>
                        <FormControl 
                            variant="outlined" 
                            style={styles.select}
                            error={errors.IdCreditCardType ? true : false}
                        >
                            <InputLabel id="select-outlined-credit-card-types">Circuito</InputLabel>
                            <Select
                                labelId="select-outlined-credit-card-types"
                                id="select-outlined-credit-card-types"
                                value={creditCard.IdCreditCardType}
                                onChange={this.handleChangeCreditCardTypes}
                                label="Circuito"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {listaCarteDiCredito}
                            </Select>
                            {errors.IdCreditCardType ? <Alert severity="error">{errors.IdCreditCardType}</Alert> : ""}
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <TextField inputProps={{maxLength: 16}} style={styles.select} placeholder="Numero Carta" label="Card Number" variant="outlined" onChange={this.handleInputChange} onFocus={this.handleInputFocus} name="number" type="tel" InputProps={{
                            inputComponent: this.NumberFormatCustom,
                        }}/>
                        {errors.CardNumber ? <Alert severity="error">{errors.CardNumber}</Alert> : ""}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField style={styles.select} placeholder="Nome" label="Name" variant="outlined" onChange={this.handleInputChange} onFocus={this.handleInputFocus} name="name" type="text" />
                        {errors.CardholderName ? <Alert severity="error">{errors.CardholderName}</Alert> : ""}
                    </Grid>
                    
                    <Grid item xs={6}>
                        <TextField value={creditCard.ExpirationDate} inputProps={{ maxLength: 5 }} style={styles.select} placeholder="Validità" label="Valid Thru" variant="outlined" onChange={this.handleInputChange} onFocus={this.handleInputFocus} name="expiry" type="tel" />
                        {errors.ExpirationDate ? <Alert severity="error">{errors.ExpirationDate}</Alert> : ""}
                    </Grid>

                    <Grid item xs={6}>
                        <TextField inputProps={{ maxLength: 4 }} style={styles.select} placeholder="CVC" label="CVC/CVV" variant="outlined" onChange={this.handleInputChange} onFocus={this.handleInputFocus} name="cvc" type="tel" pattern="\d{3,4}" InputProps={{
                            inputComponent: this.NumberFormatCustom,
                        }}/>
                        {errors.SecurityCode ? <Alert severity="error">{errors.SecurityCode}</Alert> : ""}
                    </Grid>

                 </Grid>                 
               </form>
             </>
           );
         }
       }


const mapStateToProps = (state) => {
  return {
    CreditCardBooking: state.CreditCardBooking,
    CreditCartTypes: state.CreditCartTypes,
    creditCard: state.creditCard,
    errors: state.errors,
    validate: state.validate
  };
}

export default connect(
  mapStateToProps,
  {
      GetCreditCardTypes,
      selectCreditCardTypesAction,
      changeCardHolderNameAction,
      changeExpirationDateAction,
      changeCardNumberAction,
      changeSecurityCode,
      handleDisableButton,
      nextStep
  }
)(CreditCard);

