import Octopus from "../apis/Octopus";
import {
    SAVE_BOOKING,
    // ERROR, 
    TOKEN
} from "../const";
import xml2js from "xml2js";
//import axios from "axios";
//var qs = require("qs");
import moment from "moment";


export const SaveBookings = (reservation, user, creditCard, distanziamentoSociale) => async dispatch => {


    /*EXPIRY DATE FORMAT CREDIT CARD*/
    let ExpirationDateFormat, body;
    if (creditCard.ExpirationDate) {
        let mm = creditCard.ExpirationDate.slice(0, 2);
        let yy = creditCard.ExpirationDate.slice(3, 5);
        ExpirationDateFormat = moment(yy + "-" + mm, "YY-MM").format('YYYY-MM-DD');

        body = `<?xml version="1.0" encoding="utf-8"?>
        <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
            <soap12:Body>
                <SaveBookings xmlns="http://tempuri.org/">
                    <BookingParam>
                        <Token>` + TOKEN + `</Token>
                        <IdLocation>1</IdLocation>                
                        <IdRoom>` + reservation.room + `</IdRoom>
                        <IdTimeSlot>` + reservation.idTime + `</IdTimeSlot>
                        <ReservationDate>` + reservation.date + `</ReservationDate>
                        <ReservationHour>` + reservation.time + `</ReservationHour>
                        <Children>` + reservation.person.children + `</Children>
                        <People>` + reservation.person.adults + `</People>
                        <Comments>` + user.note + ` </Comments>
                        <FidelityNumber>` + user.fidelity + `</FidelityNumber>
                        <Nome>` + user.firstName + `</Nome>  
                        <Cognome>` + user.lastName + `</Cognome>
                        <Sesso>M</Sesso> 
                        <Telefono>` + user.phone + `</Telefono>  
                        <Email>` + user.email + `</Email>
                        <PrivacyMarketing>` + user.marketing + `</PrivacyMarketing>
                        <IdCreditCardType>` + creditCard.IdCreditCardType + `</IdCreditCardType>
                        <CardholderName>` + creditCard.CardholderName + `</CardholderName>
                        <ExpirationDate>` + ExpirationDateFormat + `</ExpirationDate>
                        <CardNumber>` + creditCard.CardNumber + `</CardNumber>
                        <SecurityCode>` + creditCard.SecurityCode + `</SecurityCode>
                        <DistanziamentoSociale>` + distanziamentoSociale + `</DistanziamentoSociale>
                        <NonSoggettoDistanziamentoSociale>` + distanziamentoSociale + `</NonSoggettoDistanziamentoSociale>
                        <NumeroSeggioloni>` + reservation.person.seggiolone + `</NumeroSeggioloni>
                    </BookingParam>
                </SaveBookings>
            </soap12:Body>
        </soap12:Envelope>`;

    } else {

        body = `<?xml version="1.0" encoding="utf-8"?>
        <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
            <soap12:Body>
                <SaveBookings xmlns="http://tempuri.org/">
                    <BookingParam>
                        <Token>` + TOKEN + `</Token>
                        <IdLocation>1</IdLocation>                
                        <IdRoom>` + reservation.room + `</IdRoom>
                        <IdTimeSlot>` + reservation.idTime + `</IdTimeSlot>
                        <ReservationDate>` + reservation.date + `</ReservationDate>
                        <ReservationHour>` + reservation.time + `</ReservationHour>
                        <Children>` + reservation.person.children + `</Children>
                        <People>` + reservation.person.adults + `</People>
                        <Comments>` + user.note + `</Comments>
                        <FidelityNumber>` + user.fidelity + `</FidelityNumber>
                        <Nome>` + user.firstName + `</Nome>  
                        <Cognome>` + user.lastName + `</Cognome>
                        <Sesso>M</Sesso> 
                        <Telefono>` + user.phone + `</Telefono>  
                        <Email>` + user.email + `</Email>
                        <PrivacyMarketing>` + user.marketing + `</PrivacyMarketing>
                        <DistanziamentoSociale>` + distanziamentoSociale + `</DistanziamentoSociale>
                        <NonSoggettoDistanziamentoSociale>` + !distanziamentoSociale + `</NonSoggettoDistanziamentoSociale>
                        <NumeroSeggioloni>` + reservation.person.seggiolone + `</NumeroSeggioloni>
                    </BookingParam>
                </SaveBookings>
            </soap12:Body>
        </soap12:Envelope>`;

    }


    const promise = await Octopus.post("", body, {
        params: {
            op: "SaveBookings"
        }
    });

    var parser = new xml2js.Parser();

    parser
        .parseStringPromise(promise.data)
        .then(function(result) {

            const data =
                result["soap:Envelope"]["soap:Body"][0].SaveBookingsResponse[0][
                    "SaveBookingsResult"
                ][0];

            dispatch({
                type: SAVE_BOOKING,
                payload: data
            });
        })
        .catch(function(err) {
            console.log(err);
        });


};