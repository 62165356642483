import React, { Component } from 'react'

import Wrapper from "../Wrapper/Wrapper";
import Step from '../Step/Step'
import ButtonNextBack from "../ButtonNextBack/ButtonNextBack";

export class Form extends Component {
        render() {

           return (
               <Wrapper>
                 <Step />
                 <ButtonNextBack />
               </Wrapper>
           );
         }
       }

export default Form