import React, { Component } from 'react'
import IntlMessages from '../../helpers/IntlMessages';

import Button from '@material-ui/core/Button'
import { Grid } from '@material-ui/core'
import styles from '../../styles/styles'

import { nextStep, backStep, handleDisableButton, errorAction } from "../../actions/";
import { connect } from "react-redux";

export class ButtonNextBack extends Component {
         continue = (e) => {
           e.preventDefault();
           let { step, handleDisableButton } = this.props;
           let isDisabled = e.currentTarget.classList.contains('disabled');
           if (step === 1 && isDisabled){
             this.props.errorAction("Seleziona una data per proseguire...", "date");
           }
           else if (step === 2 && isDisabled) {
             this.props.errorAction("Seleziona una sala per proseguire...", "room");
           }
           else if (step === 4 && isDisabled) {
             this.props.errorAction("Seleziona un orario per proseguire...", "time");
           }
           else if (step === 5 && isDisabled)
           {
             this.props.errorAction("Seleziona il numero di coperti per proseguire...", "person");
           } else if (step === 6 && isDisabled) {
             this.props.errorAction("Inserisci i tuoi dati per proseguire...", "firstName");
           } else if (step === 7 && isDisabled) {
             this.props.errorAction("Inserisci i dati della tua carta per proseguire...", "IdCreditCardType");
           } else if (step === 8 && isDisabled) {
             this.props.errorAction("Accetta i termini e condizioni per proseguire...", "TermsCoditions");
           } else {
             this.props.nextStep();
             handleDisableButton(true);
           }
         }
         back = e => {
           e.preventDefault();
           this.props.backStep();
           this.props.handleDisableButton(true);
         };
         render() {
           let { step, buttonDisable } = this.props;
           
           let pulsanteIndietro; 

           if (Number(step)!==1 && Number(step)!==9) {
             pulsanteIndietro = (
               <Grid item xs={6}> 
                 <Button
                   variant="contained"
                   style={styles.button}
                   onClick={this.back}
                 >
                  <IntlMessages id="button.back" />
                 </Button>
               </Grid>
             );
           }

           let pulsanteContinua; 

           if (Number(step)!==9) {
             pulsanteContinua = (
               <Grid item xs={6}>
                 <Button
                   variant="contained"
                   color="primary"
                   style={styles.button}
                   onClick={(e) => this.continue(e)}
                   //disabled={buttonDisable}
                   className={buttonDisable ? "disabled" : ""}
                 >
                   {Number(step)===3 ? <IntlMessages id="button.proceed" /> : <IntlMessages id="button.next" />}
                 </Button>
               </Grid>
             );
           }

           return (
             <div style={{display:"flex", align: "center"}}>
                {pulsanteIndietro}
                {pulsanteContinua}
             </div>
           );
         }
       }


const mapStateToProps = state => {
  return {
    fidelity: state.user.fidelity,
    buttonDisable: state.buttonDisable,
    terminiCondizioni: state.terminiCondizioni,
    errors: state.errors
  };
};

export default connect(
  mapStateToProps,
  { nextStep, backStep, handleDisableButton, errorAction }
)(ButtonNextBack);