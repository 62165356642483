import React, {Component} from 'react';
import IntlMessages from '../../helpers/IntlMessages';

import {connect} from 'react-redux';
import {selectTimeSlot, clearTimeSlotAction} from '../../actions/';
import {getAvailableTimeSlots} from '../../actions/getAvailableTimeSlots';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import styles from '../../styles/styles';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import Moment from 'moment';
import 'moment/locale/it';
import {extendMoment} from 'moment-range';

const moment = extendMoment(Moment);


export class BookingTime extends Component {
    componentDidMount = () => {
        const {date, room} = this.props;
        this.props.getAvailableTimeSlots(date, room);
    };
    handleOnChange = (id, orario, servizio, hasCreditCard, totalSeats, peopleSeats, childrenSeats, seggioloni) => {
        if (orario === undefined) {
            this.props.selectTimeSlot('');
        } else {
            this.props.selectTimeSlot(id, orario, servizio, hasCreditCard, totalSeats, peopleSeats, childrenSeats, seggioloni);
        }
    };
    checkAvailableSeats = (seats) => {
        if (Number(seats) < 1) {
            return true;
        } else {
            return false;
        }
    };
    openAndClear = () => {
        setTimeout(() => {
            this.props.clearTimeSlotAction();
        }, 300);
    };

    renderSwitch() {
        const {
            errors,
            availableTimeSlots,
            time,
            room,
            date
        } = this.props;

        function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
        }

        function isATimeOfAugust(timeSlot) {
            var data_selected = moment(date);
            var dow = data_selected.day();
            const start = moment('2021-08-03', 'YYYY-MM-DD');
            const end = moment('2021-08-30', 'YYYY-MM-DD');
            const range = moment.range(start, end);

            if (dow !== 0 && dow !== 6 && range.contains(data_selected)) {
                let hh = timeSlot['Hour'][0].split(':')[0];
                hh = parseInt(hh);
                if (hh >= 17) {
                    return timeSlot;
                }
            } else {
                return timeSlot;
            }

        }

        switch (availableTimeSlots.length > 0) {
            case 'undefined':
                return <>{errors.timeslots ? <Alert severity="error">{errors.timeslots}</Alert> : ''}</>;
            case '':
                return <>{errors.timeslots ? <Alert severity="error">{errors.timeslots}</Alert> : ''}</>;
            case false:
                return <>{errors.timeslots ? <Alert severity="error">{errors.timeslots}</Alert> : ''}</>;
            case true:
                let options = availableTimeSlots
                    .filter(timeSlot => {
                        let firstTimeSlot = timeSlot['Description'][0];
                        if (Number(timeSlot['TotalSeats'][0]) < 4 && Number(room) == 12)
                            return false;
                        return {
                            firstTimeSlot,
                            ...timeSlot
                        };
                    })
                    .filter(isATimeOfAugust);
                return (
                    <>
                        <Autocomplete
                            options={options.sort((a, b) => {
                                return (
                                    Number(a.Hour.toString().substring(0, 2)) - Number(b.Hour.toString().substring(0, 2))
                                );
                            })}
                            disableClearable={true}
                            isSearchable={false}
                            onOpen={this.openAndClear}
                            inputValue={time}
                            groupBy={option => option['Description'][0]}
                            getOptionLabel={option => option['Hour'][0]}
                            getOptionDisabled={option =>
                                this.checkAvailableSeats(option['TotalSeats'][0])
                            }
                            onChange={(e, v) => {
                                if (v) {
                                    this.handleOnChange(v['ID'][0], v['Hour'][0], v['Description'][0], v['CreditCardBooking'][0], v['TotalSeats'][0], v['PeopleSeats'][0], v['ChildrenSeats'][0], v['Seggioloni'][0]);
                                }
                            }}
                            style={styles.select}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={<IntlMessages id="input.label.time"/>}
                                    variant="outlined"
                                    error={errors.time ? true : false}
                                    style={styles.select}
                                />
                            )}
                        />
                        {errors.time ? <Alert severity="error">{errors.time}</Alert> : ''}
                    </>
                );
            default:
                return <CircularProgress disableShrink/>;
        }
    }

    render() {
        return <>{this.renderSwitch()}</>;
    }
}

const mapStateToProps = (state) => {
    return {
        availableTimeSlots: state.availableTimeSlots,
        time: state.reservation.time,
        date: state.reservation.date,
        room: state.reservation.room,
        errors: state.errors
    };
};

export default connect(
    mapStateToProps,
    {selectTimeSlot, getAvailableTimeSlots, clearTimeSlotAction}
)(BookingTime);
