import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleDisableButton } from '../../actions'
import IntlMessages from '../../helpers/IntlMessages';

import { Grid, Typography } from '@material-ui/core'
import ReactSlidy from 'react-slidy'
import 'react-slidy/lib/index.scss'

//icons
import icoSummer from '../../assets/icons/icoSummer.png'
import icoWinter from '../../assets/icons/icoWinter.png'

import arrowL from '../../assets/icons/arrowLeft.png'
import arrowR from '../../assets/icons/arrowRight.png'

const buttonStyle = {
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    fontSize: 40,
    height: '20%',
    margin: 'auto -40px',
    padding: 0
  }

function CustomArrow({arrow, ...props}) {
    return (
      <button {...props} style={buttonStyle}>
        <img width="20" role="img" src={arrow} aria-label="Arrow" />
      </button>
    )
  }
  
function CustomArrowLeft(props) {
    return <CustomArrow {...props} arrow={arrowL} />
}

function CustomArrowRight(props) {
    return <CustomArrow {...props} arrow={arrowR} />
}

const GalleryRoom = () => {
    const idRoom = useSelector(state => state.reservation.room)
    const roomName = useSelector(state => state.reservation.roomName)
    const roomSeason = useSelector(state => state.roomSeason)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(handleDisableButton(false))
    }, [])

    const iconSeason = () => {
        const season = roomSeason[idRoom];
        switch(season){
            case "1":
                return(
                    <img src={icoSummer} width="25" style={{margin: 3,verticalAlign:'middle'}} />                    
                )
            case "2":
                return(
                    <img src={icoWinter} width="22" style={{margin: 3, verticalAlign:'middle'}} />                
                )
            case "3":
                return(
                    <><img src={icoSummer} width="25" style={{margin: 3,verticalAlign:'middle'}} /> <img src={icoWinter} width="22" style={{margin: 3, verticalAlign:'middle'}} /></>                    
                )
            default:
                return(
                    <><img src={icoSummer} width="25" style={{margin: 3,verticalAlign:'middle'}} /> <img src={icoWinter} width="22" style={{margin: 3, verticalAlign:'middle'}} /></>                    
                )
        }
    }

    return(
        <div className="RoomGallery">
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
        >
        <Grid item xs={12}>
            <Typography
                align="center" variant="h4"
            >
                <IntlMessages 
                    id="ok.message.room.selected" 
                    values={{
                        b: (...chunks) => <b>{chunks}</b>,
                        br: (chunks) => <><br />{chunks}</>,
                        room: roomName
                    }}
                />
            </Typography>
            <br />
            <Typography
                align="center" 
                gutterBottom
            >
                <IntlMessages 
                    id="room.typology" 
                /> 
                <span>
                    <IntlMessages 
                        id={`room.typology.${idRoom}`}
                        values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>,
                        }}
                    />
                </span>
            </Typography>
            <Typography
                align="center" 
                gutterBottom
                style={{verticalAlign:'middle'}}
            >
                {iconSeason()}   
                <IntlMessages 
                    id={`room.season.${idRoom}`}
                    values={{
                        b: (...chunks) => <b>{chunks}</b>,
                        br: (chunks) => <><br />{chunks}</>,
                    }}
                /> 
            </Typography>
            <Typography
                align="center" 
                gutterBottom
                style={{verticalAlign:'middle'}}
            >
                <i><IntlMessages 
                    id={`room.adults.${idRoom}`}
                    values={{
                        b: (...chunks) => <b>{chunks}</b>,
                        br: (chunks) => <><br />{chunks}</>,
                    }}
                /></i>
            </Typography>
            <hr />
            <Typography
                align="center" gutterBottom
            >
                <i>
                    <IntlMessages 
                        id={`room.description.${idRoom}`}
                        values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>,
                        }}
                    /> 
                </i> 
            </Typography>
            <hr />
            <ReactSlidy 
                itemsToPreload={3} 
                infiniteLoop
                ArrowLeft={CustomArrowLeft} 
                ArrowRight={CustomArrowRight}
            >
                <img src={`${process.env.REACT_APP_BASE_ASSETS}/assets/gallery/${idRoom} (1).jpg`} />
                <img src={`${process.env.REACT_APP_BASE_ASSETS}/assets/gallery/${idRoom} (2).jpg`} />
                <img src={`${process.env.REACT_APP_BASE_ASSETS}/assets/gallery/${idRoom} (3).jpg`} />
            </ReactSlidy>
        </Grid>
    </Grid>   
    </div>      
    )
}

export default GalleryRoom;