import React, { Component } from 'react'
import IntlMessages from '../../helpers/IntlMessages';

import { SaveBookings } from "../../actions/SaveBookings";
import { connect } from "react-redux";

import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';

export class Success extends Component {

    componentDidMount = () => {
      const { reservation, user, creditCard, distanziamentoSociale } = this.props;
      this.props.SaveBookings(reservation, user, creditCard, distanziamentoSociale);
    }

  renderSwitch() {
      const { saveBookings, user } = this.props;

      switch (saveBookings.success) {
        case "true":
            return (        
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={12}>
                    <Typography
                        align="center" variant="h4"
                    >
                      <IntlMessages 
                          id="confirmation.label.ok" 
                          values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>
                          }}
                      />                        
                    </Typography>
                    <br />
                    <Typography
                        align="center" gutterBottom
                    >
                      <IntlMessages 
                          id="confirmation.label.sendMail" 
                          values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>,
                            email: user.email
                          }}
                      />  
                    </Typography>
                  <Typography
                    align="center" gutterBottom color="#c6a53e"
                  >
                    <IntlMessages 
                          id="confirmation.label.sendMail.attention" 
                          values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>
                          }}
                      />                    
                    </Typography>
                </Grid>
            </Grid>          
            );
        case "false":
            return (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <Typography align="center" variant="h4">
                    Mi dispiace... la prenotazione non è confermata.
                  </Typography>
                  <br />
                  <Typography align="center" gutterBottom>
                    {saveBookings.message}
                  </Typography>
                </Grid>
              </Grid>
            );
        case false:
          return (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography align="center" variant="h4">
                  Mi dispiace... la prenotazione non è confermata.
                  </Typography>
                <br />
                <Typography align="center" gutterBottom>
                  Si prega di riprovare, errore momentaneo.
                </Typography>
              </Grid>
            </Grid>
          );
        default: 
            return <CircularProgress disableShrink />
    } 
  }

  render () {
    return (
          <>
            {this.renderSwitch()}
          </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        reservation: state.reservation, 
        user: state.user,
        creditCard: state.creditCard,
        saveBookings: state.saveBookings,
        distanziamentoSociale: state.distanziamentoSociale,
        //errors: state.errors.saveBookings
    };
}

export default connect(
    mapStateToProps,
    { SaveBookings }
)(Success);