export default {
    container: {
        maxWidth: 450,
        margin: "2rem auto"
    },
    input: {
        marginTop: 15,
        width: "100%"
    },
    textArea: {
        marginTop: 10
    },
    button: {
        margin: 15
    },
    DatePicker: {
        minWidth: 230
    },
    formControl: {
        width: "100%",
        marginTop: 15, 
        backgoundColor: '#424242'
    },
    select: {
        width: "100%"
    },
    checkboxLabel: {
        fontSize: "0.7rem",
        padding: "1rem"
    },
    autocomplete: {
        width: "100%"
    },
    h6: {
        alignItems: "center",
        justifyContent: "center"
    },
    icon: {
        position: "relative",
        verticalAlign: "middle",
        display: "inline-flex",
        margin: 15
    },
    caption: {
        textAlign: "center",
        verticalAlign: "middle",
        alignSelf: "center",
        color: "#ffffff"
    }
};