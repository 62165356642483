import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import IntlMessages from '../../helpers/IntlMessages';

import { connect } from "react-redux";
import {
  handleOpenDialog
} from "../../actions/";

export class TerminiCondizioni extends Component {
      handleClose = (e) => {
        e.preventDefault();
        this.props.handleOpenDialog(false);
      }
      handleOpen = (e) => {
        e.preventDefault();
        this.props.handleOpenDialog(true);
      }
    render() {
        return (
          <Dialog
            open={this.props.openDialog}
            onClose={this.handleClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              <Typography align="center">
                <IntlMessages 
                    id="termsCondition" 
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      br: (chunks) => <><br />{chunks}</>
                    }}
                />                 
              </Typography>
            </DialogTitle>

            <DialogContent dividers={true}>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                {`Si consiglia il rispetto della puntualità dell’orario prenotato.`}
                <br />
                <br />
                {`In caso di ritardo:`}
                <br />
                <br />
                {`a) l'orario di prenotazione sarà considerato valido`}{" "}
                <span>{`entro e non oltre 15 minuti di ritardo`}</span>.{" "}
                {`Allo scadere di tale termine, la prenotazione verrà cancellata e il tavolo assegnato ad altro cliente.`}
                <br />
                <br />
                {`b) al momento della prenotazione, si potrà ampliare il tempo di ritardo di ulteriori 15 minuti, e quindi la prenotazione rimarrà valida per`}{" "}
                <span>{`30 minuti oltre l’orario indicato`}</span>;{" "}
                {`ciò potrà avvenire solo ed esclusivamente previo pagamento di una caparra confirmatoria pari ad`}{" "}
                <span>{`Euro 100`}</span>{" "}
                {`da rilasciare con pagamento online tramite Paypal al momento della prenotazione. Se il cliente si presenterà entro 30 minuti dall’ora della prenotazione, la somma verrà imputata al pagamento del conto, oltre tale orario la somma verrà invece trattenuta.`}
                <br />
                <br />
                {`Si fa pertanto presente che tale deroga è valida solo ove sia stato rilasciato la caparra confirmatoria. In caso contrario il ritardo non potrà superare i 15 minuti dall’orario della prenotazione allo scadere della quale la prenotazione si intenderà risolta.`}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    terminiCondizioni: state.terminiCondizioni,
    openDialog: state.openDialog,
  };
}

export default connect(
  mapStateToProps,
  { handleOpenDialog }
)(TerminiCondizioni);