import React, { Component } from 'react'
import IntlMessages from '../../helpers/IntlMessages';

import { connect } from "react-redux";
import {
  changeFirstName,
  changeLastName,
  changeEmail,
  changePhone,
  changeNote,
  changeMarketing,
  changeFidelity,
  CheckFidelity,
  handleHasFidelity,
  handleDisableButton
} from "../../actions/";

import { Grid, TextField, FormGroup, FormControlLabel, Checkbox, Typography, Link } from '@material-ui/core'
import styles from '../../styles/styles'
import MuiAlert from "@material-ui/lab/Alert";


export class UserDetails extends Component {
         handleCheckFidelity = idCard => {
           this.props.CheckFidelity(idCard);
         };
        handleChangeFidelity = (e) => {
          let { value, maxLength } = e.target;
          const number = value.slice(0, maxLength);
          this.props.changeFidelity(number);
        }
         renderSwitch() {

           function Alert(props) {
             return <MuiAlert elevation={6} variant="filled" {...props} />;
           }

           const {
             firstName,
             lastName,
             email,
             phone,
             //note,
             marketing,
             errors,
             changeFirstName,
             changeLastName,
             changeEmail,
             changePhone,
             //changeNote,
             changeMarketing,
             hasFidelity,
             validate,
             handleDisableButton
           } = this.props;

           if (
             validate.firstName &&
             validate.lastName &&
             validate.email &&
             validate.phone
           ) {
             handleDisableButton(false);
           } else {
             handleDisableButton(true);
           }


           if (!hasFidelity) {
             return (
               <>
                 <Grid
                   container
                   direction="row"
                   justify="center"
                   alignItems="center"
                   spacing={2}
                 >
                   <Grid item xs={12} sm={6}>
                     <TextField
                       fullWidth
                       required
                       name="firstName"
                       label={<IntlMessages 
                        id="input.label.firstName" 
                        values={{
                          b: (...chunks) => <b>{chunks}</b>,
                          br: (chunks) => <><br />{chunks}</>,
                        }}
                      /> }
                       variant="outlined"
                       value={firstName}
                       onChange={e =>
                         changeFirstName(e.target.value.toUpperCase())
                       }
                       success={validate.firstName ? "true" : "false"}
                       error={errors.firstName ? true : false}
                     />
                     {errors.firstName ? (
                       <Alert severity="error">{errors.firstName}</Alert>
                     ) : (
                       ""
                     )}
                   </Grid>
                   <Grid item xs={12} sm={6}>
                     <TextField
                       fullWidth
                       required
                       name="lastName"
                       label={<IntlMessages 
                        id="input.label.lastName" 
                        values={{
                          b: (...chunks) => <b>{chunks}</b>,
                          br: (chunks) => <><br />{chunks}</>,
                        }}
                      /> }
                       variant="outlined"
                       value={lastName}
                       onChange={e =>
                         changeLastName(e.target.value.toUpperCase())
                       }
                       error={errors.lastName ? true : false}
                     />
                     {errors.lastName ? (
                       <Alert severity="error">{errors.lastName}</Alert>
                     ) : (
                       ""
                     )}
                   </Grid>
                 </Grid>

                 <Grid
                   container
                   direction="row"
                   justify="center"
                   alignItems="center"
                   spacing={2}
                 >
                   <Grid item xs={12} sm={6}>
                     <TextField
                       fullWidth
                       required
                       name="email"
                       label="Email"
                       variant="outlined"
                       value={email}
                       onChange={e => changeEmail(e.target.value)}
                       error={errors.email ? true : false}
                     />
                     {errors.email ? (
                       <Alert severity="error">{errors.email}</Alert>
                     ) : (
                       ""
                     )}
                   </Grid>
                   <Grid item xs={12} sm={6}>
                     <TextField
                       fullWidth
                       required
                       type="number"
                       name="phone"
                       label={<IntlMessages 
                        id="input.label.phone" 
                        values={{
                          b: (...chunks) => <b>{chunks}</b>,
                          br: (chunks) => <><br />{chunks}</>,
                        }}
                      /> }
                       variant="outlined"
                       value={phone}
                       onChange={e => changePhone(e.target.value)}
                       error={errors.phone ? true : false}
                     />
                     {errors.phone ? (
                       <Alert severity="error">{errors.phone}</Alert>
                     ) : (
                       ""
                     )}
                   </Grid>
                 </Grid>

                 {/* <Grid
                   container
                   direction="column"
                   justify="center"
                   alignItems="center"
                   spacing={1}
                 >
                   <Grid item xs={12} style={{ width: "100%" }}>
                     <TextField
                       fullWidth
                       name="note"
                       label="Note"
                       variant="outlined"
                       value={note}
                       multiline
                       rows="4"
                       style={styles.textArea}
                       onChange={e => changeNote(e.target.value)}
                     />
                   </Grid>
                 </Grid> */}

                 <FormGroup row>
                   <FormControlLabel
                     control={
                       <Checkbox
                         name="marketing"
                         checked={marketing}
                         onChange={e => changeMarketing(e.target.checked)}
                         color="primary"
                       />
                     }
                     label={
                       <Typography style={styles.checkboxLabel}>
                         <IntlMessages 
                            id="marketing" 
                            values={{
                              b: (...chunks) => <b>{chunks}</b>,
                              br: (chunks) => <><br />{chunks}</>,
                              link: () => <Link href="https://qvintoroma.it/privacy-policy/" target="_blank"><IntlMessages id="marketingLink" /></Link>
                            }}
                          />  
                       </Typography>
                     }
                   />
                 </FormGroup>
               </>
             );
           } else {
             const { fidelity, errors } = this.props;
             return (
               <Grid
                 container
                 direction="row"
                 justify="center"
                 alignItems="center"
               >
                 <TextField
                   fullWidth
                   name="fidelity"
                   label={<IntlMessages 
                    id="input.label.fidelity" 
                    values={{
                      b: (...chunks) => <b>{chunks}</b>,
                      br: (chunks) => <><br />{chunks}</>,
                    }}
                  /> }
                   variant="outlined"
                   value={fidelity}
                   inputProps={{
                     maxLength: "7"
                   }}
                   onChange={e => this.handleChangeFidelity(e)}
                   onKeyUp={e => this.handleCheckFidelity(e.target.value)}
                   error={errors.fidelity ? true : false}
                 />
                 {errors.fidelity ? (
                   <Alert
                     severity={
                       errors.fidelity === "Tessera non trovata!"
                         ? "error"
                         : "success"
                     }
                   >
                     {errors.fidelity}
                   </Alert>
                 ) : (
                   ""
                 )}
               </Grid>
             );
           }
         }
         render() {
           const { hasFidelity, 
            handleHasFidelity 
          } = this.props;
           return (
             <>
               <form noValidate autoComplete="off">
                 {/* <Grid
                   container
                   direction="row"
                   justify="center"
                   alignItems="center"
                 >
                   <Typography align="center" variant="body2">
                      <IntlMessages 
                          id="fidelity" 
                          values={{
                            b: (...chunks) => <b>{chunks}</b>,
                            br: (chunks) => <><br />{chunks}</>
                          }}
                      />                     
                     <Switch
                       onChange={x => handleHasFidelity(x.target.checked)}
                       checked={hasFidelity}
                       color="primary"
                     />
                   </Typography>
                 </Grid> */}
                 {this.renderSwitch()}
               </form>
             </>
           );
         }
       }


const mapStateToProps = (state) => {
  return {
    hasFidelity: state.hasFidelity,
    fidelity: state.fidelity,
    firstName: state.firstName,
    lastName: state.lastName,
    email: state.email,
    phone: state.phone,
    note: state.note,
    marketing: state.marketing,
    errors: state.errors,
    validate: state.validate
  };
}

export default connect(
  mapStateToProps,
  {
    changeFirstName,
    changeLastName,
    changeEmail,
    changePhone,
    changeNote,
    changeMarketing,
    changeFidelity,
    CheckFidelity,
    handleHasFidelity,
    handleDisableButton
  }
)(UserDetails);

