import {
    defaultLocale,
    localeOptions
} from '../const/defaultValues';

export const getCurrentLanguage = () => {
    let language = defaultLocale;
    try {
      language = localStorage.getItem('currentLanguage') &&
        localeOptions.filter(
          (x) => x.id === localStorage.getItem('currentLanguage')
        ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
    } catch (error) {
      console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
      language = defaultLocale;
    }
    return language;
};


export const setCurrentLanguage = (locale) => {
try {
    localStorage.setItem('currentLanguage', locale);
} catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error)
}
};
  