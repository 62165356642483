export const NEXT_STEP = "NEXT_STEP";
export const BACK_STEP = "BACK_STEP";
export const GET_ROOMS = "GET_ROOMS";
export const SELECT_ROOM = "SELECT_ROOM";
export const CLEAR_ROOM = "CLEAR_ROOM";
export const ROOM_NAME = "ROOM_NAME";
export const ROOM_HAS_CHILD = "ROOM_HAS_CHILD";
export const GET_DAYS = "GET_DAYS";
export const SELECT_DAY = "SELECT_DAY";
export const GET_TIMESLOTS = "GET_TIMESLOTS";
export const SELECT_TIME = "SELECT_TIME";
export const CLEAR_TIMESLOT = "CLEAR_TIMESLOT";
export const SELECT_TIME_ID = "SELECT_TIME_ID";
export const SELECT_SERVIZIO = "SELECT_SERVIZIO";
export const SELECT_ADULTS = "SELECT_ADULTS";
export const SELECT_CHILDREN = "SELECT_CHILDREN";
export const SELECT_SEGGIOLONE = "SELECT_SEGGIOLONE";
export const CHANGE_FIRSTNAME = "CHANGE_FIRSTNAME";
export const CHANGE_LASTNAME = "CHANGE_LASTNAME";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_PHONE = "CHANGE_PHONE";
export const CHANGE_NOTE = "CHANGE_NOTE";
export const CHANGE_MARKETING = "CHANGE_MARKETING";
export const HAS_FIDELITY = "HAS_FIDELITY";
export const CHANGE_FIDELITY = "CHANGE_FIDELITY";
export const CHECK_FIDELITY = "CHECK_FIDELITY";
export const DISABLE_BUTTON = "DISABLE_BUTTON";
export const ACCEPT_TERMINI = "ACCEPT_TERMINI";
export const GET_FIDELITY = "GET_FIDELITY";
export const GET_FIDELITY_ERROR = "GET_FIDELITY_ERROR";
export const OPEN_DIALOG = "OPEN_DIALOG";
export const ERROR = "ERROR";
export const VALIDATE = "VALIDATE";
export const SAVE_BOOKING = "SAVE_BOOKING";
export const SEND_EMAIL_CONFIRM = "SEND_EMAIL_CONFIRM";
export const CREDIT_CARD_ROOM = "CREDIT_CARD_ROOM";
export const GET_CREDITCARD = "GET_CREDITCARD";
export const CHANGE_CARDNUMBER = "CHANGE_CARDNUMBER";
export const CHANGE_EXPIRATIONDATE = "CHANGE_EXPIRATIONDATE";
export const CHANGE_CARDHOLDERNAME = "CHANGE_CARDHOLDERNAME";
export const CHANGE_IDCREDITCARD = "CHANGE_IDCREDITCARD";
export const SECURITY_CODE = "SECURITY_CODE";
export const DISTANZIAMENTO = "DISTANZIAMENTO";
export const TOTAL_SEATS = "TOTAL_SEATS";
export const PEOPLE_SEATS = "PEOPLE_SEATS";
export const CHILD_SEATS = "CHILD_SEATS";
export const SEGGIOLONI = "SEGGIOLONI";
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const TOKEN =
    "EAAAAEVvYqVo2z/ONpu8rEe0v94lL9iTs6CAbLXlUsrqYKYhz/InmKHIxq5A83RVKSesI0i2bv8kkQq8xPE8BEfU894/XYzR9l3hiTsDjGY0YUhEKrPIbdpKUEZBjKDX993MpwIWQkQUFRTlcd13KimoTmP3Lx82Oox3bYae1Eh1dwLDXARjQZo8m9WwhmlokqnDmN+R1AdJO9U4+4Fk8vPdNsiwrUkF4+0qiMu8RqaaDqKL65mZphXGX5v0knfXvw+ErXSpGcRdXE8PYWH/vQ+Hia3knFiyRQUhHKeTIY8oEsHufGfJvl1UGjFGW66OcuSjF/1097JGyv2OB/w3AjCqaljU8AN2ne3ijkIqx2sog2E+kcwHNwl+aan2KwlLscIfAi/1Ibxi6JfBe6K791gWmGZeXVff7e8ayCnDNtOEazZFKwt+pmbmPvhoHcxctJdFtDCgCrLz6uNoeu74ulay6Fe8Ttv0QYsjrM4ox7k27wVgWS8jrkc7w4SFOagqycUXf9/hRjaYYDV9e/EpzkPUFG+pY3UXfkyf94s4IddXvli+k5M+IbRwcf59qfw1OPRNB7CzozhBtWZcLDrXuBkdpwW9hFRhj84IrmC+LD1p0ANYVkUM/miEy0cP2iMwaXEci5ZfuqIzVNXOyHCiu/Dp0MeJKXhf+AHKJq7vM2a5YChIUghvNN0+iixIDj+tc80TUfhPU5fq2C6RcFM4xvaWw7MjPvqr5y0f2BPcCvQbWCjBCeIZrMPe7v8qXgwLG2hddQ==";