import React, { Component } from 'react';
import IntlMessages from '../../helpers/IntlMessages';

import { connect } from "react-redux";
import { selectDataAction } from "../../actions/";
//import { getAvailableDays } from "../../actions/getAvailableDays";

import moment from 'moment';
import MomentUtils from "@date-io/moment";
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'moment/locale/it'

import MuiAlert from "@material-ui/lab/Alert";

import styles from '../../styles/styles';

export class BookingDate extends Component {

    handleOnChange = e => {
        this.props.selectDataAction(e.format("YYYY-MM-DD"));
    };

    disableWeekends = (date) => {
        const giorniChiusuraSpeciali = [
            "2022-01-13",
        ];

        const giorniApertureSpeciali = [
            "2021-12-20",
        ];
        
        if (giorniApertureSpeciali.includes(date.format("YYYY-MM-DD"))) {
            return false;
        }

        if (giorniChiusuraSpeciali.includes(date.format("YYYY-MM-DD"))) {
            return true;
        }

         if (giorniApertureSpeciali.includes(date.format("YYYY-MM-DD")) && date.day() === 1) {
             return false;
         }
         
         if (giorniChiusuraSpeciali.includes(date.format("YYYY-MM-DD")) || date.day() === 1) {
             return true;
         }
    }

    render() {

        const { date, errors } = this.props

        function Alert(props) {
            return <MuiAlert elevation = { 6 }
            variant = "filled" {...props }
            />;
        }

        return ( 
            <>
                <MuiPickersUtilsProvider utils = { MomentUtils }
                moment = { moment }
                locale = "it" >
                    <DatePicker disablePast orientation = "portrait"
                    variant = "static"
                    openTo = "date"
                    format = "YYYY-MM-DD"
                    margin = "normal"
                    label = {<IntlMessages id="input.label.data" />}
                    value = { date }
                    onChange = { e => this.handleOnChange(e) }
                    style = { styles.DatePicker }
                    shouldDisableDate = { date => this.disableWeekends(date) }
                    />  
                </MuiPickersUtilsProvider > 
                {errors ? <Alert severity = "error" > { errors } </Alert> : ""}  
            </>
            );
        }
    }

    const mapStateToProps = (state) => {
        return {
            //availableDays: state.availableDays,
            date: state.reservation.date,
            errors: state.errors.date
        };
    }

    export default connect(
        mapStateToProps, {
            selectDataAction,
            //getAvailableDays 
        }
    )(BookingDate);