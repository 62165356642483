import React, { Component } from 'react'
import { connect } from 'react-redux';
import { selectRoomAction, clearRoomAction } from "../../actions/";
import { GetRestaurantRooms } from "../../actions/GetRestaurantRooms";
import IntlMessages from '../../helpers/IntlMessages';

//import { TOKEN } from '../../const';

import {Alert as MuiAlert, Autocomplete } from "@material-ui/lab";
import {Popper, CircularProgress, TextField} from "@material-ui/core";

import styles from '../../styles/styles'
import moment from 'moment';
export class Rooms extends Component {
         componentDidMount() {
           //this.props.GetRestaurantRooms(TOKEN, 1, this.props.date);
           this.props.GetRestaurantRooms(this.props.date);
         }


        handleOnChange = (RoomName) => {
          function isRoomSelect(room) {
            //return room["RoomName"] === RoomName;
            return room["RoomName"][0] === RoomName;
          }
          const roomSelected = this.props.rooms.filter(isRoomSelect);
          //this.props.selectRoomAction(roomSelected[0]["Id"], roomSelected[0]["RoomName"], roomSelected[0]["HasChildrenArea"]);
          this.props.selectRoomAction(roomSelected[0]["Id"][0], roomSelected[0]["RoomName"][0]);
         };
        openAndClear = () => {
          setTimeout(() => {
            this.props.clearRoomAction();
          }, 300);
        }
        renderSwitch() {
          const { rooms, room, errors, date } = this.props;

          function Alert(props) {
            return <MuiAlert elevation={6} variant="filled" {...props} />;
          }

          switch (rooms.length > 0){
            case "undefined":
              return <>{errors.rooms ? <Alert severity="error">{errors.rooms}</Alert> : ""}</>
            case "":
              return <>{ errors.rooms ? <Alert severity="error">{errors.rooms}</Alert> : "" }</>
            case false:
              return <>{errors.rooms ? <Alert severity="error">{errors.rooms}</Alert> : ""}</>
            case true:
              let optionsRoom = rooms.filter(room => {
                //switch(room["RoomName"]){
                switch (room["RoomName"][0]) {
                  case "Lemon Room":
                    //room["Description"] = "Ristorante";
                    room["Description"] = ["Ristorante"];
                    break;
                  case "Cristal Room":
                    //room["Description"] = "Ristorante";
                    room["Description"] = ["Ristorante"];
                    break;
                  case "Secret Garden":
                    //room["Description"] = "Ristorante";
                    room["Description"] = ["Ristorante"];
                    break;
                  case "Apollo":
                    //room["Description"] = "Garden";
                    room["Description"] = ["Ristorante"];
                    break;                  
                  case "The Roof":
                    //room["Description"] = "Roof";
                    room["Description"] = ["Roof"];
                    break;
                  case "Lounge Bar":
                    //room["Description"] = "Bar";
                    room["Description"] = ["Bar"];
                    break;
                  case "Cool Garden 1":
                    //room["Description"] = "Garden";
                    room["Description"] = ["Garden"];
                    break;
                  case "Cool Garden 2":
                    //room["Description"] = "Garden";
                    room["Description"] = ["Garden"];
                    break;
                  case "Jungle Garden":
                    //room["Description"] = "Garden";
                    room["Description"] = ["Garden"];
                    break;
                  case "Igloo Garden":
                    //room["Description"] = "Garden";
                    room["Description"] = ["Garden"];
                    break;
                  case "Cube":
                    //room["Description"] = "Garden";
                    room["Description"] = ["Garden"];
                    break;
                  case "Notting Hill":
                    //room["Description"] = "Garden";
                    room["Description"] = ["Garden"];
                    break;
                  default:
                    //room["Description"] = "Ristorante";
                    room["Description"] = ["Ristorante"];
                    break;
                }
                //let firstRoom = room["Description"];
                let firstRoom = room["Description"][0];
                return {
                  firstRoom,
                  ...room
                }
              });


              const filterRoomByDate = (el, date) => {
                /*if(
                  moment(date, "YYYY-MM-DD").isBetween('2021-10-10', '2021-10-24')
                ) {
                  return el["Id"][0] !== "12" && el["Id"][0] !== "14" && el["Id"][0] !== "17"
                } else if(
                  moment(date, "YYYY-MM-DD").isBetween('2021-10-23', '2021-11-01')
                ) {
                  return el["Id"][0] !== "16" && el["Id"][0] !== "2" && el["Id"][0] !== "8" && el["Id"][0] !== "9" && el["Id"][0] !== "14" && el["Id"][0] !== "12" && el["Id"][0] !== "15"  && el["Id"][0] !== "17"
                } else {
                  return  el["Id"][0] !== "2" && el["Id"][0] !== "14" && el["Id"][0] !== "17"
                }*/

                  return  el["Id"][0] !== "14" && el["Id"][0] !== "17"
              }

              let optionFilterDate = optionsRoom.filter(el => filterRoomByDate(el,date))
              .sort(function(a, b) {
                var nameA = a.RoomName[0].toUpperCase(); // ignora maiuscole e minuscole
                var nameB = b.RoomName[0].toUpperCase(); // ignora maiuscole e minuscole
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              })
              .sort(function(a, b) {
                var nameA = a.Description[0].toUpperCase(); // ignora maiuscole e minuscole
                var nameB = b.Description[0].toUpperCase(); // ignora maiuscole e minuscole
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              });


              return (
                <>
                  <Autocomplete
                    options={optionFilterDate}
                    //groupBy={option => option["Description"]}
                    groupBy={option => option["Description"][0]}
                    getOptionLabel={option => {
                      if (option["RoomName"]) {
                        //return option["RoomName"]
                        return option["RoomName"][0]
                      }
                    }}
                    openOnFocus={true}
                    selectOnFocus={true}
                    disableClearable={true}
                    onOpen={this.openAndClear}
                    inputValue={room}
                    isSearchable={false}
                    onChange={e => {
                      this.handleOnChange(e.currentTarget.innerHTML);
                    }}
                    style={styles.select}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={<IntlMessages id="input.label.room" />}
                        variant="outlined"
                        error={errors.room ? true : false}
                        style={styles.select}
                      />
                    )}
                  />
                  {errors.room ? <Alert severity="error">{errors.room}</Alert> : ""}
                </>
              )
            default:
              return <CircularProgress disableShrink />
          }
        }

         render() {
           return (
             <>
               {this.renderSwitch()}
             </>
           );
         }
       }

const mapStateToProps = (state) => {
    return {
        date: state.reservation.date,
        room: state.reservation.roomName,
        rooms: state.rooms,
        errors: state.errors
    }
}

export default connect(
  mapStateToProps,
  { GetRestaurantRooms, selectRoomAction, clearRoomAction }
)(Rooms);