import { combineReducers } from 'redux';
import { getCurrentLanguage } from '../helpers/Utils';

import moment from 'moment';
import {

    NEXT_STEP,
    BACK_STEP,
    GET_ROOMS,
    SELECT_ROOM,
    CLEAR_ROOM,
    ROOM_NAME,
    GET_DAYS,
    SELECT_DAY,
    GET_TIMESLOTS,
    SELECT_TIME,
    CLEAR_TIMESLOT,
    SELECT_TIME_ID,
    SELECT_SERVIZIO,
    SELECT_ADULTS,
    SELECT_CHILDREN,
    SELECT_SEGGIOLONE,
    CHANGE_FIRSTNAME,
    CHANGE_LASTNAME,
    CHANGE_EMAIL,
    CHANGE_PHONE,
    CHANGE_NOTE,
    CHANGE_MARKETING,
    CHANGE_FIDELITY,
    HAS_FIDELITY,
    CHECK_FIDELITY,
    OPEN_DIALOG,
    DISABLE_BUTTON,
    ACCEPT_TERMINI,
    ERROR,
    VALIDATE,
    SAVE_BOOKING,
    CREDIT_CARD_ROOM,
    GET_CREDITCARD,
    CHANGE_CARDNUMBER,
    CHANGE_EXPIRATIONDATE,
    CHANGE_CARDHOLDERNAME,
    CHANGE_IDCREDITCARD,
    SECURITY_CODE,
    DISTANZIAMENTO,
    TOTAL_SEATS,
    PEOPLE_SEATS,
    CHILD_SEATS,
    SEGGIOLONI,
    CHANGE_LOCALE,
} from "../const";

const TODAY = moment().format('YYYY-MM-DD');

/* reservation: {
    room: "",
    roomName: "",
    date: TODAY,
    idTime: "",
    time: "",
    servizio: "",
    person: {
        adults: 0,
        children: 0,
        seggiolone: 0
    }
},
 */
const initialState = {
    step: 1,
    reservation: {
        room: "",
        roomName: "",
        date: TODAY,
        idTime: "",
        time: "",
        servizio: "",
        person: {
            adults: 0,
            children: 0,
            seggiolone: 0
        }
    },
    user: {
        fidelity: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        note: "",
        marketing: false
    },
    buttonDisable: true,
    terminiCondizioni: false,
    rooms: [],
    availableDays: "",
    availableTimeSlots: "",
    hasFidelity: false,
    openDialog: false,
    fidelityCheck: "",
    CreditCartTypes: [],
    CreditCardBooking: false,
    creditCard: {
        IdCreditCardType: '',
        CardholderName: '',
        ExpirationDate: '',
        CardNumber: '',
        SecurityCode: ''
    },
    distanziamentoSociale: false,
    totalSeats: 0,
    peopleSeats: 2,
    childrenSeats: 0,
    seggioloni: 0,
    error: {
        date: "",
        rooms: "",
        room: "",
        timeslots: "",
        time: "",
        person: "",
        children: "",
        fidelity: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        IdCreditCardType: '',
        CardholderName: '',
        ExpirationDate: '',
        CardNumber: '',
        SecurityCode: '',
        saveBookings: null,
        distanziamentoSociale: null,
        TermsCoditions: ''
    },
    validate: {
        fidelity: false,
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        IdCreditCardType: false,
        CardholderName: false,
        ExpirationDate: false,
        CardNumber: false,
        SecurityCode: false,
        distanziamentoSociale: false,
    },
    saveBookings: {
        success: "",
        message: "",
        CancellationToken: ''
    },
    settings: {
        locale: getCurrentLanguage()
    },
    roomSeason: {
        1:"3",
        2:"1",
        3:"3",
        4:"3",
        6:"3",
        7:"3",
        8:"1",
        9:"1",      
        14:"2",
        15:"1",
    }
};

const roomSeason = (state = initialState.roomSeason, action) => {
    return state;
};

const changeLocalReducer = (state = initialState.settings, action) => {
    switch (action.type) {
        case CHANGE_LOCALE:
            return { ...state, locale: action.payload };
        default:
            return state;
    }
};

const nextStepReducer = (state = initialState.step, action) => {
    switch (action.type) {
        case NEXT_STEP:
            return state + 1
        case BACK_STEP:
            return state === 1 ? 1 : state - 1
        default:
            return state;
    }
};

const GetRestaurantRoomsReducer = (state = initialState.rooms, action) => {
    switch (action.type) {
        case GET_ROOMS:
            return action.payload;
        default:
            return state;
    }
};

const handleRoomReducer = (state = initialState.reservation.room, action) => {
    switch (action.type) {
        case SELECT_ROOM:
            return action.payload
        case CLEAR_ROOM:
            return ""
        default:
            return state;
    }
};
const handleRoomNameReducer = (state = initialState.reservation.roomName, action) => {
    switch (action.type) {
        case ROOM_NAME:
            return action.payload
        case CLEAR_ROOM:
            return ""
        default:
            return state;
    }
};
const getAvailableDaysReducer = (
    state = initialState.availableDays,
    action
) => {
    switch (action.type) {
        case GET_DAYS:
            return action.payload;
        default:
            return state;
    }
};

const handleDateReducer = (state = initialState.reservation.date, action) => {
    switch (action.type) {
        case SELECT_DAY:
            return action.payload;
        default:
            return state;
    }
};

const getAvailableTimeSlotsReducer = (
    state = initialState.availableTimeSlots,
    action
) => {
    switch (action.type) {
        case GET_TIMESLOTS:
            return action.payload;
        default:
            return state;
    }
};

const handleTimeReducer = (state = initialState.reservation.time, action) => {
    switch (action.type) {
        case SELECT_TIME:
            return action.payload;
        case CLEAR_TIMESLOT:
            return ""
        default:
            return state;
    }
};
const handleIDTimeReducer = (state = initialState.reservation.idTime, action) => {
    switch (action.type) {
        case SELECT_TIME_ID:
            return action.payload;
        case CLEAR_TIMESLOT:
            return ""
        default:
            return state;
    }
};
const handleServizioReducer = (
    state = initialState.reservation.servizio,
    action
) => {
    switch (action.type) {
        case SELECT_SERVIZIO:
            return action.payload;
        case CLEAR_TIMESLOT:
            return ""
        default:
            return state;
    }
};
const handleCreditCardBookingReducer = (state = initialState.CreditCardBooking, action) => {
    switch (action.type) {
        case CREDIT_CARD_ROOM:
            return action.payload
        case CLEAR_TIMESLOT:
            return ""
        default:
            return state;
    }
};
const handleTotalSeatsReducer = (state = initialState.totalSeats, action) => {
    switch (action.type) {
        case TOTAL_SEATS:
            return action.payload
        case CLEAR_TIMESLOT:
            return ""
        default:
            return state;
    }
};
const handlePeopleSeatsReducer = (state = initialState.peopleSeats, action) => {
    switch (action.type) {
        case PEOPLE_SEATS:
            return action.payload
        case CLEAR_TIMESLOT:
            return ""
        default:
            return state;
    }
};
const handleChildrenSeatsReducer = (state = initialState.childrenSeats, action) => {
    switch (action.type) {
        case CHILD_SEATS:
            return action.payload
        case CLEAR_TIMESLOT:
            return ""
        default:
            return state;
    }
};
const handleSeggioloniReducer = (state = initialState.seggioloni, action) => {
    switch (action.type) {
        case SEGGIOLONI:
            return action.payload
        case CLEAR_TIMESLOT:
            return ""
        default:
            return state;
    }
};



const handleAdultsReducer = (
    state = initialState.reservation.person.adults,
    action
) => {
    switch (action.type) {
        case SELECT_ADULTS:
            return action.payload;
        default:
            return state;
    }
};

const handleChildrenReducer = (
    state = initialState.reservation.person.children,
    action
) => {
    switch (action.type) {
        case SELECT_CHILDREN:
            return action.payload;
        default:
            return state;
    }
};
const handleSeggioloneReducer = (
    state = initialState.reservation.person.seggiolone,
    action
) => {
    switch (action.type) {
        case SELECT_SEGGIOLONE:
            return action.payload;
        default:
            return state;
    }
};
const handleFirstNameReducer = (
    state = initialState.user.firstName,
    action
) => {
    switch (action.type) {
        case CHANGE_FIRSTNAME:
            return action.payload;
        default:
            return state;
    }
};
const handleLastNameReducer = (
    state = initialState.user.lastName,
    action
) => {
    switch (action.type) {
        case CHANGE_LASTNAME:
            return action.payload;
        default:
            return state;
    }
};
const handleEmailReducer = (
    state = initialState.user.email,
    action
) => {
    switch (action.type) {
        case CHANGE_EMAIL:
            return action.payload;
        default:
            return state;
    }
};
const handlePhoneReducer = (
    state = initialState.user.phone,
    action
) => {
    switch (action.type) {
        case CHANGE_PHONE:
            return action.payload;
        default:
            return state;
    }
};
const handleNoteReducer = (
    state = initialState.user.note,
    action
) => {
    switch (action.type) {
        case CHANGE_NOTE:
            return action.payload;
        default:
            return state;
    }
};
const handleMarketingReducer = (
    state = initialState.user.marketing,
    action
) => {
    switch (action.type) {
        case CHANGE_MARKETING:
            return action.payload;
        default:
            return state;
    }
};

const handleFidelityReducer = (
    state = initialState.user.fidelity,
    action
) => {
    switch (action.type) {
        case CHANGE_FIDELITY:
            return action.payload
        default:
            return state
    }
}
const handlehasFidelityReducer = (
    state = initialState.hasFidelity,
    action
) => {
    switch (action.type) {
        case HAS_FIDELITY:
            return action.payload;
        default:
            return state;
    }
};

const handleCheckFidelityReducer = (
    state = initialState.fidelityCheck,
    action
) => {
    switch (action.type) {
        case CHECK_FIDELITY:
            return action.payload;
        default:
            return state;
    }
};

const handleDisableButtonReducer = (
    state = initialState.buttonDisable,
    action
) => {
    switch (action.type) {
        case DISABLE_BUTTON:
            return action.payload;
        default:
            return state;
    }
};

const handleOpenDialogReducer = (state = initialState.openDialog, action) => {
    switch (action.type) {
        case OPEN_DIALOG:
            return action.payload;
        default:
            return state;
    }
};

const handleTerminiCondizioniReducer = (
    state = initialState.terminiCondizioni,
    action
) => {
    switch (action.type) {
        case ACCEPT_TERMINI:
            return action.payload;
        default:
            return state;
    }
};

const validationErroReducer = (state = initialState.error, action) => {
    let { errors, input } = action.payload ? action.payload : '';
    switch (action.type) {
        case ERROR:
            return {
                ...state,
                [input]: errors
            }
        default:
            return state;
    }
};

const validationReducer = (state = initialState.validate, action) => {
    let { validate, input } = action.payload ? action.payload : '';
    switch (action.type) {
        case VALIDATE:
            return {
                ...state,
                [input]: validate
            };
        default:
            return state;
    }
};

const handleGetCreditCardTypesReducer = (
    state = initialState.CreditCartTypes,
    action
) => {
    switch (action.type) {
        case GET_CREDITCARD:
            return action.payload;
        default:
            return state;
    }
};

const handleIdCreditCardType = (
    state = initialState.creditCard.IdCreditCardType,
    action
) => {
    switch (action.type) {
        case CHANGE_IDCREDITCARD:
            return action.payload;
        default:
            return state;
    }
};

const handleCardholderName = (
    state = initialState.creditCard.CardholderName,
    action
) => {
    switch (action.type) {
        case CHANGE_CARDHOLDERNAME:
            return action.payload;
        default:
            return state;
    }
};
const handleExpirationDate = (
    state = initialState.creditCard.ExpirationDate,
    action
) => {
    switch (action.type) {
        case CHANGE_EXPIRATIONDATE:
            return action.payload;
        default:
            return state;
    }
};
const handleCardNumber = (
    state = initialState.creditCard.CardNumber,
    action
) => {
    switch (action.type) {
        case CHANGE_CARDNUMBER:
            return action.payload;
        default:
            return state;
    }
};

const handlerSecurityCode = (state = initialState.creditCard.SecurityCode, action) => {
    switch(action.type) {
        case SECURITY_CODE:
            return action.payload;
        default:
            return state;
    }
}

const handleDistanziamentoSocialeReducer = (state = initialState.distanziamentoSociale, action) => {
    switch(action.type) {
        case DISTANZIAMENTO:
            return !state;
        default:
            return state;
    }
}


const handleSaveBookingsReducer = (
    state = initialState.saveBookings,
    action
) => {
    let data = action.payload;
    switch (action.type) {
        case SAVE_BOOKING:
            return {
                success: data["Confirmed"][0],
                InformationMessage: data["InformationMessage"],
                message: data["Message"],
                CancellationToken: data["CancellationToken"]
            };
        default:
            return state;
    }
};

export default combineReducers({
    step: nextStepReducer,
    reservation: combineReducers({
        room: handleRoomReducer,
        roomName: handleRoomNameReducer,
        date: handleDateReducer,
        idTime: handleIDTimeReducer,
        time: handleTimeReducer,
        servizio: handleServizioReducer,
        person: combineReducers({
            adults: handleAdultsReducer,
            children: handleChildrenReducer,
            seggiolone: handleSeggioloneReducer
        })
    }),
    user: combineReducers({
        fidelity: handleFidelityReducer,
        firstName: handleFirstNameReducer,
        lastName: handleLastNameReducer,
        email: handleEmailReducer,
        phone: handlePhoneReducer,
        note: handleNoteReducer,
        marketing: handleMarketingReducer
    }),
    rooms: GetRestaurantRoomsReducer,
    availableDays: getAvailableDaysReducer,
    availableTimeSlots: getAvailableTimeSlotsReducer,
    buttonDisable: handleDisableButtonReducer,
    terminiCondizioni: handleTerminiCondizioniReducer,
    hasFidelity: handlehasFidelityReducer,
    openDialog: handleOpenDialogReducer,
    fidelityCheck: handleCheckFidelityReducer,
    distanziamentoSociale: handleDistanziamentoSocialeReducer,
    totalSeats: handleTotalSeatsReducer,
    peopleSeats: handlePeopleSeatsReducer,
    childrenSeats: handleChildrenSeatsReducer,
    seggioloni: handleSeggioloniReducer,
    errors: validationErroReducer,
    validate: validationReducer,
    CreditCardBooking: handleCreditCardBookingReducer,
    CreditCartTypes: handleGetCreditCardTypesReducer,
    creditCard: combineReducers({
        IdCreditCardType: handleIdCreditCardType,
        CardholderName: handleCardholderName,
        ExpirationDate: handleExpirationDate,
        CardNumber: handleCardNumber,
        SecurityCode: handlerSecurityCode
    }),
    saveBookings: handleSaveBookingsReducer,
    settings: changeLocalReducer,
    roomSeason
});