import Octopus from "../apis/Octopus";
import { GET_TIMESLOTS, ERROR, TOKEN } from "../const";
import xml2js from "xml2js";

export const getAvailableTimeSlots = (date, room) => async dispatch => {
    let body = `<?xml version="1.0" encoding="utf-8" ?>
                <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                <soap:Body>
                    <GetAvailableTimeSlots xmlns="http://tempuri.org/">
                    <p>
                        <Token>` + TOKEN + `</Token>
                        <IdLocation>1</IdLocation>
                        <IdRoom>` + room + `</IdRoom>
                        <ReservationDate>` + date + `</ReservationDate>
                    </p>
                    </GetAvailableTimeSlots>
                </soap:Body>
                </soap:Envelope>`;

    const promise = await Octopus.post("", body, {
        params: {
            op: "GetAvailableTimeSlots"
        }
    });

    var parser = new xml2js.Parser();

    parser
        .parseStringPromise(promise.data)
        .then(function (result) {

            const data =
                result["soap:Envelope"]["soap:Body"][0]
                    .GetAvailableTimeSlotsResponse[0][
                "GetAvailableTimeSlotsResult"
                ][0]["TimeSlots"][0]["TimeSlot"];

            dispatch({
                type: GET_TIMESLOTS,
                payload: data
            });
        })
        .catch(function (err) {
            let input = "time";
            dispatch({
                type: ERROR,
                payload: { err, input }
            });
        });
};   