import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import errorValidation from './middleware/errorValidation';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import reducers from './reducers';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

var store;

if(process.env.REACT_APP_STAGE ==="prod") {
  store = createStore(
    reducers,
    applyMiddleware(thunk, errorValidation)
  );
} else {
  store = createStore(
    reducers,
    applyMiddleware(thunk, errorValidation, logger)
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#c6a53e"
    },
    type: "dark"
  },
  typography: {},
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#c6a53e"
      }
    },
    MuiButton: {
      containedPrimary: {
        color: "white",
        fontWeight: "bold"
      }
    },
    MuiPickersDay: {
      daySelected: {
        color: "white",
        fontWeight: "bold"
      }
    },
    MuiInputBase: {
      formControl: {
        color: "#c6a53e",
        backgroundColor: "#424242"

      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#c6a53e"
      }
    },
    MuiFormLabel: {
      root: {
        //color: "black"
      }
    },
    MuiSelect: {
      root: {
        //color: "black"
      }
    },
    MuiTypography: {
      body1: {
        color: "white"
      },
      body2: {
        color: "#c6a53e"
      },
      colorTextSecondary: {
        color: "#c6a53e"
      }
    },
    MuiCheckbox: {
      root: {
        color: "#c6a53e"
      }
    },
    MuiDialog: {
      paperScrollPaper: {
        backgroundColor: "white"
      }
    },
    MuiDialogContentText: {
      root: {
        color: "black"
      }
    }
  }
});

//console.log(theme);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);