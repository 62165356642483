import React, { Component } from 'react'

import { connect } from "react-redux";
import { nextStep, backStep } from "../../actions/";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { Grid } from "@material-ui/core";
import styles from "../../styles/styles";

export class Wrapper extends Component {

         render() {
           const { step } = this.props;
           const updateChildrenWithProps = React.Children.map(
             this.props.children,
             (child, i) => {
               return React.cloneElement(child, {
                 //this properties are available as a props in child components
                 step: step
               });
             }
           );
           return (
             <MuiThemeProvider>
               <React.Fragment>
                 <Grid
                   container
                   direction="column"
                   justify="center"
                   alignItems="center"
                   style={styles.container}
                 >
                   {updateChildrenWithProps}
                 </Grid>
               </React.Fragment>
             </MuiThemeProvider>
           );
         }
       }

const mapStateToProps = (state) => {
  return {
    step: state.step
  };
}
export default connect(
  mapStateToProps,
  { nextStep, backStep }
)(Wrapper);